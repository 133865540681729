import {styled} from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";

export const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  background: "#fff",
  padding: "1rem",
}));

export const tableHolderSx = {
  minWidth: 800,
};

export const paginationSx = {
  margin: "1rem",
  display: "flex",
  paddingTop: "1rem",
  justifyContent: "end",
}

export const centerFlexBox = {
  display: "flex",
  justifyContent: "center",
  alignContent:"center",
}