import {styled} from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";

export const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  overflowY: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

export const tableHolderSx = {
  minWidth: 800,
};

export const txtFieldBorderStyle = {
  width: "45px",
  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};
