import React, {useState} from "react";

import {Box, Grid, Typography, Alert, Divider, Button} from "@mui/material";

import {useNavigate} from "react-router-dom";

import FormProvider from "../../../components/hook-form";

import {useForm} from "react-hook-form";

import {yupResolver} from "@hookform/resolvers/yup";

import * as Yup from "yup";

import YearCustomDatePicker from "../../../components/custom-datepicker/YearCustomDatePicker";

import {useDispatch, useSelector} from "react-redux";

import {LoadingButton} from "@mui/lab";

import {toast} from "react-toastify";

import {getOpponentsPaymentsStatistics} from "../../../redux/services/finance_service";

//--- Styles ---
import {
  boxStyle,
  containerStyle,
  createLawsuitButtonStyle,
  createReportButtonStyle,
  lawsuitTextStyle,
  bigFlexBoxStyle,
  flexBoxStyle,
  txtFieldBorderStyle,
  labelCheckBoxStyle,
  lableSx,
  dividerStyle,
} from "../../../styles/AccountsPageStyles";

import SelectSingleClient from "../../../components/custom-select/clients/SelectSingleClient";

import SelectMultipleOpponents from "../../../components/custom-select/opponents/SelectMultipleOpponents";

import {PieChart} from "@mui/x-charts";

//_______________________________________________________

const CustomBox = ({label, value, color}) => (
  <Box display={"flex"} alignItems={"center"} justifyContent={"start"} flexDirection={"column"} mb={2}>
    <Typography sx={labelCheckBoxStyle}>{label}</Typography>
    <Box
      sx={{
        p: '16px 33px 16px 33px',
        border: `1px solid ${color}`,
        borderRadius: '9px',
        background: color,
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 600,
          color: '#fff',
        }}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

//_______________________________________________________

export default function AccountsPage() {

  const {isLoadingCreate} = useSelector((state) => state.finance);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [selectedOpponents, setSelectedOpponents] = useState([]);

  const [all, setIsAll] = useState(false);

  const [selectedClient, setSelectedClient] = useState(null);

  const [data, setData] = useState(null);

  const dataItems = [
    {id: 1, key: "knet", label: "كي نت", value: data?.knet, color: "#d9a99b"},
    {id: 2, key: "branch", label: "فرع", value: data?.branch, color: "#6A8490"},
    {id: 3, key: "company", label: "شركة", value: data?.company, color: "#91b9cb"},
    {id: 4, key: "link", label: "رابط", value: data?.link, color: "#706e6f"}
  ];

  const FormSchema = Yup.object().shape({
    date_before: Yup.string().required("الحقل مطلوب"),
    date_after: Yup.string().required("الحقل مطلوب"),
    client: Yup.string().required("يجب اختيار موكل"),
  });

  const defaultValues = {
    date_before: null,
    date_after: null,
    client: "",
    opponent: "",
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {setValue, handleSubmit, formState: {errors}} = methods;

  const handleChange = (value) => {
    setIsAll(value?.id === "all");
    setValue("client", value?.id, {shouldValidate: true});
    setSelectedClient(value);

    // reset opponent on change client
    setSelectedOpponents([]);
    setValue("opponents", []);
  };

  const handleChangeOpponent = (values) => {
    const ids = values?.map((item) => item.id);
    setValue("opponents", ids);
    setSelectedOpponents(ids);
  };

  const createOpponentsParams = (opponents) => {
    return opponents.reduce((result, opponent, index) => {
      result[`opponents[${index}]`] = opponent;
      return result;
    }, {});
  };

  const onSubmit = async (data) => {

    let params = {
      client: data.client === "all" ? "" : data?.client,
      created_at_before: data?.date_before,
      created_at_after: data?.date_after,
    };

    if (data?.opponents) {
      const opponentsParams = createOpponentsParams(data.opponents);
      params = {...params, ...opponentsParams};
    }

    dispatch(getOpponentsPaymentsStatistics(params)).then((response) => {
      if (getOpponentsPaymentsStatistics.fulfilled.match(response)) {
        setData(response?.payload?.data?.data);
      } else {
        toast.error(response?.payload?.data?.message || "هناك خطأ ما");
        response?.payload?.errors?.map((error, index) => toast.warn(error));
      }
    });

  };

//___________________________________________________________________

  return (
    <>
      {/** Top Side */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

        <Box sx={bigFlexBoxStyle}>
          <Typography sx={lawsuitTextStyle}>الحسابات</Typography>
          <Box sx={flexBoxStyle}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              sx={createLawsuitButtonStyle}
              loading={isLoadingCreate}
            >
              توليد
            </LoadingButton>

            <Button
              fullWidth
              onClick={() => navigate(-1)}
              size="large"
              type="submit"
              variant="Contained"
              sx={createReportButtonStyle}
            >
              الغاء
            </Button>
          </Box>
        </Box>

        <Divider sx={dividerStyle}/>

        <Box sx={containerStyle}>

          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit.message}</Alert>
          )}

          <Grid container columnSpacing={1} gap={2}>

            <Grid item xs={12} sm={12} md={5}>

              {/*  clients  */}
              <Box>
                <Typography sx={{...lableSx}}> الموكل</Typography>
                <SelectSingleClient
                  name={"clients"}
                  allClientOption={true}
                  handleChange={handleChange}
                  value={selectedClient}
                />
                {errors?.client &&
                <Alert severity="error">{errors.client?.message}</Alert>
                }
              </Box>

              {/*  opponents  */}
              {!all && selectedClient && (
                <Box>
                  <Typography sx={{...lableSx}}> الخصم</Typography>
                  <SelectMultipleOpponents
                    name={"opponents"}
                    handleChange={handleChangeOpponent}
                    values={selectedOpponents}
                    clientId={selectedClient?.id}
                  />
                </Box>
              )}

              {/*  dates  */}
              <Box sx={{...boxStyle, mt: "10px"}}>
                {" "}
                <Typography sx={{...lableSx}}> التاريخ</Typography>
                <Box sx={bigFlexBoxStyle}>
                  <box>
                    <YearCustomDatePicker name="date_after" sx={{...txtFieldBorderStyle}} setValue={setValue}/>
                    {errors?.date_after &&
                    <Alert severity="error">{errors.date_after?.message}</Alert>
                    }
                  </box>
                  <Typography sx={{...lableSx, mr: "6px", ml: "6px", paddingBottom: "0px"}}>
                    الى
                  </Typography>
                  <box>
                    <YearCustomDatePicker
                      name="date_before"
                      sx={{...txtFieldBorderStyle}}
                      setValue={setValue}
                    />
                    {errors?.date_before &&
                    <Alert severity="error">{errors.date_before?.message}</Alert>
                    }
                  </box>
                </Box>
              </Box>

            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {data && <>
                {/* Result Chart */}
                <Box px={6}>
                  <PieChart
                    series={[
                      {
                        data: dataItems.map(item => ({
                          id: item.id,
                          value: item.value,
                          label: item.label
                        }))
                      }
                    ]}
                    width={500}
                    height={300}
                    colors={dataItems.map(item => item.color)}
                  />
                </Box>

                {/* Result Data */}
                <Box display="flex" alignItems="center" justifyContent="space-around" mt={3} px={3}>
                  {dataItems.map(item => (
                    <CustomBox key={item.id} label={item.label} value={item.value} color={item.color}/>
                  ))}
                </Box>
              </>}
            </Grid>

          </Grid>
        </Box>
      </FormProvider>
    </>
  );
}
