import {createAsyncThunk} from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  STATISTICS
export const getStatisticService = createAsyncThunk(
  "statistic/getStatisticService",
  async (thunkAPI) => {
    try {
      return await axios.get(URLS.getStatistics);
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getStatisticDataService = createAsyncThunk(
  "statistic/getStatisticData",
  async (thunkAPI) => {
    try {
      return await axios.get(URLS.getStatisticsData);

    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
