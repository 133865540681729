import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    mt: "10px",
    "& .MuiFormLabel-root": {
      fontSize: "16px",
      boxShadow: "#101828",
    },
    "& .MuiOutlinedInput-root": {
      background: "#fff",
      borderRadius: "5px",
      boxShadow: "#101828",
      height: "44px",
      margin: "0px",
      border: "1px solid #D0D5DD",
      "&:hover fieldset": {
        border: "1px solid #D0D5DD",
        borderColor: "#D0D5DD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D0D5DD",
      },
    },
  },
}));

export const formControlSx = {
  width: "100%",
  mt: "10px",
  "& .MuiFormLabel-root": {
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    margin: "0px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  }
};

export const autocompleteStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'wrap',
    },
    '& .MuiAutocomplete-tag': {
      maxWidth: '100%',
      whiteSpace: 'normal',
    },
  },
  textField: {
    height: 'auto',
  },
}));

export const autocompleteSx = {
  '& .MuiAutocomplete-inputRoot': {
    flexWrap: 'wrap',
  },
  '& .MuiAutocomplete-tag': {
    maxWidth: '100%',
    whiteSpace: 'normal',
  },
  '& .MuiInputBase-input': {
    padding: '0px !important',
    minHeight: '0.6rem',
  },
};

export const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  bgcolor: "#CCDDE4",
  pt: {xs: "60px", md: "24px", lg: "30px"},
  pb: {xs: "40px", md: "24px", lg: "30px"},
  px: {xs: "10px", sm: "20px", md: "20px", lg: "30px"},
  width: {xs: "95%", sm: "543px", md: "800px", lg: "548px"},
  height: "auto",
};

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

export const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};

export const boxOfActionButtonStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  mt: "30px",
};

export const boxOfModalStyle = {position: "absolute", top: "22px", left: "22px"};

export const nameSx = {
  fontWeight: 400,
  color: "#2F2B2C",
  fontSize: "14px",
  lineHeight: "20px",
};

export const selectBoxSx = {
  width: "100%",
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    margin: "0px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  }
};