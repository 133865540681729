export const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};

export const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: {xs: "15px", sm: "0px"},
};

export const topSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const topCancleButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2F2B2C",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const changeButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#556e68",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "#ccdde4",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const dividerStyle = {
  mt: "20px",
};
