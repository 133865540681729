import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import {IconButton, Tooltip} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";

import React, {useEffect, useState} from "react";

import {styled} from "@mui/material/styles";

import CustudtExpenseListTableRow from "./TypesExpenseListTableRow";
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from "../../../../components/table";
import Iconify from "../../../../components/iconify";
import Scrollbar from "../../../../components/scrollbar/Scrollbar";
import LoadingTableRows from "../../../../components/loadingSkeleton/LoadingTableRows";
import TypesExpenseListTableRow from "./TypesExpenseListTableRow";
import {getExpensesService} from "../../../../redux/services/debt_collection_service";
import EnhancedTablePagination from "../../../../components/table/EnhancedTablePagination";

// ----------------------------------------------------------------------

// --------- Style ------------

const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

const tableHolderSx = {
  minWidth: 800,
};

const TABLE_HEAD = [
  {id: "المصاريف", label: "المصاريف", align: "center"},
  {id: "القيمة الافتراضية", label: "القيمة الافتراضية", align: "center"},
  {id: "الوصف", label: "الوصف", align: "center"},
];

// ----------------------------------------------------------------------

export default function TypesExpenseList() {
  const {
    dense,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    setSelected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const dispatch = useDispatch();

  const {isLoading, expenses, expensesCount, expensesLastPage} = useSelector(
    (state) => state.deptCollection
  );
  const [tableData, setTableData] = useState([]);

  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState(page);
  const [pageSize, setPageSize] = useState(10);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setPage(1);
    setInputPage(1);
  };

  const handleInputChange = (event, value) => {
    setInputPage(value);
  };

  useEffect(() => {
    dispatch(getExpensesService({page, pageSize}));
    setInputPage(page);
  }, [page, pageSize]);

  useEffect(() => {
    setTableData(expenses);
  }, [dispatch, expenses]);

  return (
    <>
      <StyledTableContainer>
        <TableSelectedAction
          dense={dense}
          numSelected={selected?.length}
          rowCount={tableData?.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData?.map((row) => row?.id)
            )
          }
          action={
            <Tooltip title="حذف">
              <IconButton color="#FFFFF">
                <Iconify icon="eva:trash-2-outline"/>
              </IconButton>
            </Tooltip>
          }
        />{" "}
        <Scrollbar>
          <Table size={dense ? "small" : "medium"} sx={{...tableHolderSx}}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row) => row?.id)
                )
              }
            />

            <TableBody>
              <>
                {!isLoading ? (
                  <>
                    {tableData?.map((row) => (
                      <TypesExpenseListTableRow
                        key={row.id}
                        row={row}
                        onSelectRow={() => onSelectRow(row?.id)}
                        selected={selected?.includes(row?.id)}
                      />
                    ))}
                  </>
                ) : (
                  <LoadingTableRows number={3} cellsNum={3}/>
                )}
              </>
              {tableData?.length === 0 && !isLoading && <TableNoData isNotFound/>}
            </TableBody>
          </Table>
        </Scrollbar>

        <EnhancedTablePagination
          count={expensesCount}
          page={page}
          setPage={setPage}
          lastPage={expensesLastPage}
          rowsPerPage={rowsPerPage}
          handlePagination={handleChange}
          onRowsPerPageChange={onChangeRowsPerPage}
          dense={dense}
          onChangeDense={onChangeDense}
          currentPage={inputPage}
          handleCurrentPageChange={handleInputChange}
          pageSize={pageSize}
          handlePageSizeChange={handlePageSizeChange}
        />

      </StyledTableContainer>
    </>
  );
}
