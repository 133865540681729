import React, { useState } from "react";

import PropTypes from "prop-types";

import {
  TableRow,
  TableCell,
  Typography,
  Stack,
  Box,
  Tooltip,
  Avatar,
  Checkbox,
  MenuItem,
  IconButton,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import Iconify from "../../../../../components/iconify";

import MenuPopover from "../../../../../components/menu-popover";

import { setProcedureId } from "../../../../../redux/slices/deptCollection";

// ------------------------------------------------------------------------

const innerCellBorderStyle = {
  borderWidth: "1px 0px",
  borderColor: "#eee",
  borderStyle: "solid",
};

const cellTextSx = {
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "#2F2B2C",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#CCDDE4",
  },
}));

const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

const menuStyle = {
  width: "auto",
  boxShadow: " 2px 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "5px",
  border: "1px solid rgba(103, 103, 103, 0.1)",
  p: "16px 22px 16px 22px",
};

const menuItemTextStyle = {
  ml: "10px",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  color: "#2F2B2C",
};
//_______________________________________________________
ProceduresTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

//___________________________________________________________________
export default function ProceduresTableRow(props) {
  const { row, selected, onSelectRow, selectedColumns } = props;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    event.stopPropagation();
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  const maxLength = 20;

  const statement = row?.statement || "";

  const name = `${row?.employee_object?.first_name || ""} ${
    row?.employee_object?.last_name || ""
  }`;

  const renderCellContent = (column) => {
    switch (column.id) {
      case "0":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.type?.name_ar || "-"}
          </Typography>
        );
      case "1":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.procedure_date || "-"}
          </Typography>
        );
      case "2":
        return (
          <>
            <Typography
              sx={{
                ...cellTextSx,
                wordBreak: "break-word",
                width: "200px", // Set your desired fixed width here

                overflow: "hidden", // Optional: control overflow behavior

                whiteSpace: "normal", // Allow text to wrap
                //  textAlign: "justify",
              }}
            >
              {row?.statement}
            </Typography>
          </>
        );
      case "3":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {" "}
            {row?.upcoming_procedure_date || "-"}
          </Typography>
        );
      case "4":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.phone_numbers_object?.map((phone, index) => (
              <div>{phone?.phone_number}</div>
            ))}{" "}
          </Typography>
        );
      case "5":
        return (
          <Typography sx={{ ...cellTextSx }}>
            {row?.employee?.profile_picture && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip title={name}>
                  <Avatar alt={row.emp} src={row?.employee?.profile_picture} />
                </Tooltip>
              </Box>
            )}
          </Typography>
        );
      default:
        return null;
    }
  };

  function handleEditItem(e) {
    e.stopPropagation();
    navigate("/dashboard/collection/edit-procedure", {state: row});
    dispatch(setProcedureId(row?.id));
    handleClosePopover();
  }

  // -------- JSX Code -------

  return (
    <>
      <StyledTableRow hover selected={selected} onClick={(e) => handleEditItem(e)}>
        <TableCell padding="checkbox" sx={innerCellBorderStyle}>
          {/* <Checkbox
            checked={selected?.includes(row?.id)}
            onClick={(e) => {
              e.stopPropagation();
              onSelectRow(row?.id);
            }}
            sx={{ ...checkBoxSx }}
          /> */}
        </TableCell>
        {selectedColumns.map((column) => (
          <TableCell key={column.id} align="center" sx={innerCellBorderStyle}>
            <Stack alignItems="center" justifyContent="center">
              {renderCellContent(column)}
            </Stack>
          </TableCell>
        ))}

        {/** ACTIONS*/}
        <TableCell
          onClick={(e) => {
            e.stopPropagation();
          }}
          align="center"
          sx={innerCellBorderStyle}
        >
          <Typography component="div" sx={{ ...cellTextSx }}>
            <IconButton
              color={openPopover ? "inherit" : "default"}
              onClick={handleOpenPopover}
            >
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>
          </Typography>
        </TableCell>

        <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="top-right"
          sx={menuStyle}
        >
          {/*********************edit****************************************** */}
          <MenuItem onClick={(e) => handleEditItem(e)}>
            <img alt="" disabledEffect src="/assets/icons/edit.svg" />
            <Typography sx={{ ...menuItemTextStyle }}>تعديل</Typography>
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setProcedureId(row?.id));
              navigate("/dashboard/collection/create-procedure");
              handleClosePopover();
            }}
          >
            <img alt="" disabledEffect src="/assets/icons/plus.svg" />
            <Typography sx={{ ...menuItemTextStyle }}>إضافة</Typography>
          </MenuItem>
          {row?.attachments?.length > 0 && (
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                row?.attachments?.forEach((attachment) => {
                  window.open(attachment.file, "_blank");
                });
              }}
            >
              <img alt="" disabledEffect src="/assets/icons/eye.svg" />
              <Typography sx={{ ...menuItemTextStyle }}>عرض المرفق</Typography>
            </MenuItem>
          )}

          {/**************************************************************** */}
        </MenuPopover>
      </StyledTableRow>
    </>
  );
}
