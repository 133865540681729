import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {Box, MenuItem, TextField} from "@mui/material";
import {autocompleteSx, selectBoxSx} from "../../../styles/AssignEmployeeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemText from "@mui/material/ListItemText";
import LoadMoreButton from "../../../utils/LoadMoreButton";
import {getOpponentsForCollection} from "../../../redux/services/debt_collection_service";

function SelectMultipleOpponents({name, clientId, handleChange, values = []}) {

  const dispatch = useDispatch();
  const {
    isLoading,
    opponents,
    opponentsNext,
    opponentsLastPage,
  } = useSelector((state) => state.general);

  const [opponentsList, setOpponentsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (opponentsList && opponentsList?.length === 0 && opponents.length > 0) {
      setOpponentsList(opponents);
    }
  }, [opponents]);

  useEffect(() => {
    const fetchOpponents = async () => {
      const res = await dispatch(getOpponentsForCollection({
        id: clientId,
        page: 1,
        search_value: searchValue
      }));
      setOpponentsList(res.payload?.data?.data || []);
      setCurrentPage(1);
    };
    if (clientId)
      fetchOpponents();
  }, [searchValue, dispatch, clientId]);

  const handleLoadMoreOpponents = async (nextPage) => {
    const res = await dispatch(getOpponentsForCollection({
      id: clientId,
      page: nextPage,
      search_value: searchValue
    }));
    setOpponentsList((prev) => [...prev, ...res.payload?.data?.data]);
    setCurrentPage(nextPage);
  };

  const HandlerSearchForOpponent = (e) => {
    const search_value = e.target.value;
    setSearchValue(search_value);
  };

  const handleChangeOption = (event, value) => {
    setSearchValue("");
    handleChange(value);
  };

  const convertValuesToSelected = useCallback((values) => {
    if (values && Array.isArray(values)) {
      if (typeof values[0] === 'object') {
        return values;
      } else if (typeof values[0] === 'number') {
        return opponentsList.filter(item => values.includes(item.id));
      }
    }
    return values;
  }, [opponentsList]);

  const initialValue = convertValuesToSelected(values);

  return (
    <Box sx={selectBoxSx}>
      <Autocomplete
        multiple={true}
        name={name}
        options={opponentsList}
        getOptionLabel={(option) => `${option?.full_name}`}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={handleChangeOption}
        value={initialValue}
        defaultValue={initialValue}
        sx={autocompleteSx}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="ابحث عن خصم"
            onChange={HandlerSearchForOpponent}
            value={searchValue}
            InputProps={{
              ...params.InputProps,
              style: {height: 'auto'},
            }}
          />
        )}
        renderOption={(props, option, {selected}) => (
          <MenuItem {...props} key={option?.id} selected={selected}>
            <ListItemText primary={`${option?.full_name}`}/>
          </MenuItem>
        )}
        ListboxComponent={(props) => (
          <ul {...props}>
            {props.children}
            {opponentsNext && (
              <LoadMoreButton
                lastPage={opponentsLastPage}
                onLoadMore={handleLoadMoreOpponents}
                loading={isLoading}
                activePage={currentPage}
              />
            )}
          </ul>
        )}
      />
    </Box>
  );
}

SelectMultipleOpponents.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
};

export default SelectMultipleOpponents;
