import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import {getAllClientsService} from "../../../redux/services/general_service";
import {Box, MenuItem, TextField} from "@mui/material";
import {autocompleteSx, selectBoxSx} from "../../../styles/AssignEmployeeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import ListItemText from "@mui/material/ListItemText";
import LoadMoreButton from "../../../utils/LoadMoreButton";

function SelectSingleClient({name, handleChange, value, allClientOption}) {

  const dispatch = useDispatch();
  const {
    clients,
    clientsLastPage,
    clientsNext,
    isLoading,
  } = useSelector((state) => state.general);

  const [clientsList, setClientsList] = useState(clients || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (clientsList.length === 0 && clients?.length > 0) {
      if (allClientOption)
        setClientsList([{id: 'all', first_name: 'جميع الموكلين'}, ...clients]);
      else
        setClientsList([clients]);
    }
  }, [clients]);

  useEffect(() => {
    const fetchClients = async () => {
      const res = await dispatch(getAllClientsService({page: 1, search_value: searchValue}));

      if (allClientOption)
        setClientsList([{id: 'all', full_name: 'جميع الموكلين'}, ...res.payload?.data?.data]);
      else
        setClientsList(res.payload?.data?.data);

      setCurrentPage(1);
    };
    fetchClients();
  }, [searchValue, dispatch]);

  const handleLoadMoreClients = async (nextPage) => {
    const res = await dispatch(getAllClientsService({page: nextPage, search_value: searchValue}));
    setClientsList((prev) => [...prev, ...res.payload?.data?.data]);
    setCurrentPage(nextPage);
  };

  const HandlerSearchForClient = (e) => {
    const search_value = e.target.value;
    setSearchValue(search_value);
  };

  const handleChangeOption = (event, value) => {
    handleChange(value);
    setSearchValue("");
  };

  const convertValueToSelected = useCallback((value) => {

    if (typeof value === 'object') {
      return value;
    } else if (typeof value === 'number') {
      return clientsList.filter((item) => item.id === value);
    }

  }, [clientsList]);

  const initialValue = convertValueToSelected(value);

  return (
    <Box sx={selectBoxSx}>
      <Autocomplete
        name={name}
        options={clientsList}
        getOptionLabel={(option) => `${option?.full_name}`}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={handleChangeOption}
        value={initialValue}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        sx={autocompleteSx}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="ابحث عن موكل"
            onChange={HandlerSearchForClient}
            InputProps={{
              ...params.InputProps,
              style: {height: 'auto'},
            }}
          />
        )}
        renderOption={(props, option, {selected}) => (
          <MenuItem {...props} key={option?.id} selected={selected}>
            <ListItemText primary={`${option?.full_name}`}/>
          </MenuItem>
        )}
        ListboxComponent={(props) => (
          <ul {...props}>
            {props.children}
            {clientsNext && (
              <LoadMoreButton
                lastPage={clientsLastPage}
                onLoadMore={handleLoadMoreClients}
                loading={isLoading}
                activePage={currentPage}
              />
            )}
          </ul>
        )}
      />
    </Box>
  );

}

SelectSingleClient.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  handleChange: PropTypes.func.isRequired,
};

export default SelectSingleClient;
