import React, {useEffect, useState} from "react";
import {Box, Typography, Button, Alert} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPaymentFileData} from "../../../redux/services/debt_collection_service";
import {
  bigFlexBoxStyle,
  flexBoxStyle,
  lawsuitTextStyle,
  topSaveButtonStyle,
} from "../../../styles/OpponentFilterStyles"
import PaymentsList from "../../../sections/dashboard/collection/table/upload-file-list/PaymentsList";
import {setPaymentFileDataLoaded} from "../../../redux/slices/deptCollection";


export default function UploadPaymentFilePage() {

  const {
    tempId,
    tempData,
    paymentFileData,
    paymentFileDataCount,
    paymentFileDataLoaded,
    errorInPaymentFileData,
  } = useSelector((state) => state.deptCollection);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!paymentFileDataLoaded)
      dispatch(getPaymentFileData({id: tempId, page}));

  }, [paymentFileDataLoaded]);

  useEffect(() => {
    dispatch(getPaymentFileData({id: tempId, page}));
  }, [page, setPage]);


  return (
    <>
      {
        errorInPaymentFileData &&
        <Alert severity="error">{errorInPaymentFileData}</Alert>
      }
      <Box sx={{...bigFlexBoxStyle, flexDirection: {xs: "column", sm: "row"}}}>
        <Box sx={flexBoxStyle}>
          &nbsp; &nbsp;
          <img
            src={"/assets/icons/arrow-left.svg"}
            alt="back"
            style={{marginLeft: "10px", cursor: "pointer"}}
            onClick={() => navigate(-1)}
          />
          <Typography sx={lawsuitTextStyle}>بيانات ملف الماليات</Typography>
        </Box>

        {/** actions */}
        <Box sx={flexBoxStyle}>
          <Button
            fullWidth
            size="large"
            type="button"
            variant="Contained"
            sx={topSaveButtonStyle}
            onClick={() => {
              navigate("/dashboard/collection/detailes");
            }}
          >
            تم
          </Button>
        </Box>

      </Box>

      <PaymentsList
        count={paymentFileDataCount}
        page={page}
        setPage={setPage}
        data={paymentFileData}
        setDataLoaded={setPaymentFileDataLoaded}
      />

    </>
  );
}
