import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function DataTableHeadCell({width, children, ...rest}) {
  return (
    <Box
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={{
        color: "#6A8490",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        borderBottom: `1px solid #E0E0E0`,
      }}
    >
      <Box
        {...rest}
        position="relative"
        textAlign={"center"}
        color="secondary"
        opacity={0.7}
      >
        {children}
      </Box>
    </Box>
  );
}

DataTableHeadCell.defaultProps = {
  width: 'auto',
  sorted: 'none',
};

DataTableHeadCell.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default DataTableHeadCell;
