import PropTypes from "prop-types";
import {Box, MenuItem, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {autocompleteSx, selectBoxSx} from "../../styles/AssignEmployeeStyles";
import ListItemText from "@mui/material/ListItemText";
import LoadMoreButton from "../../utils/LoadMoreButton";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getEmployeeService} from "../../redux/services/debt_collection_service";

function EmployeeSelect({name, handleChange, value, values, multiple}) {

  const {
    isLoadingEmployees,
    empNext,
    empLastPage,
    employees
  } = useSelector((state) => state.deptCollection);

  const [employeesList, setEmployeesList] = useState(employees || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (employeesList.length === 0 && employees?.length > 0) {
      setEmployeesList(employees);
    }
  }, [employees]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const res = await dispatch(getEmployeeService({page: 1, search_value: searchValue}));
      setEmployeesList(res.payload.data.data);
      setCurrentPage(1);
    };
    fetchEmployees();
  }, [searchValue, dispatch]);

  const handleLoadMoreEmployees = async (nextPage) => {
    const res = await dispatch(getEmployeeService({page: nextPage, search_value: searchValue}));
    setEmployeesList((prev) => [...prev, ...res.payload?.data?.data]);
    setCurrentPage(nextPage);
  };

  const searchHandlerEmployee = (e) => {
    const search_value = e.target.value;
    setSearchValue(search_value);
  };

  const handleChangeOption = (event, value) => {
    setSearchValue("");
    handleChange(value);
  };

  // Convert values to the appropriate format if needed
  const convertValuesToSelected = useCallback((values) => {
    if (values && Array.isArray(values)) {
      if (typeof values[0] === 'object') {
        return values;
      } else if (typeof values[0] === 'number') {
        return employeesList.filter(emp => values.includes(emp.id));
      }
    }
    return values;
  }, [employeesList]);

  // Handle single value or multiple values are passed
  const initialValue = value ? value : convertValuesToSelected(values);

  return (
    <Box sx={selectBoxSx}>
      <Autocomplete
        multiple={multiple ?? false}
        name={name}
        options={employeesList}
        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={handleChangeOption}
        value={initialValue}
        defaultValue={initialValue}
        sx={autocompleteSx}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="ابحث عن الموظف"
            onChange={searchHandlerEmployee}
            value={searchValue}
            InputProps={{
              ...params.InputProps,
              style: {height: 'auto'},
            }}
          />
        )}
        renderOption={(props, option, {selected}) => (
          <MenuItem {...props} key={option?.id} selected={selected}>
            <ListItemText primary={`${option?.first_name} ${option?.last_name}`}/>
          </MenuItem>
        )}
        ListboxComponent={(props) => (
          <ul {...props}>
            {props.children}
            {empNext && (
              <LoadMoreButton
                lastPage={empLastPage}
                onLoadMore={handleLoadMoreEmployees}
                loading={isLoadingEmployees}
                activePage={currentPage}
              />
            )}
          </ul>
        )}
      />
    </Box>
  );
}

EmployeeSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  values: PropTypes.array,
  handleChange: PropTypes.func,
  multiple: PropTypes.bool,
};

export default EmployeeSelect;
