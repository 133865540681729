import {Helmet} from "react-helmet-async";

import React, {useEffect, useState} from "react";

import {Box, Typography, Divider, Alert, Grid} from "@mui/material";

import {useNavigate} from "react-router-dom";

import {useDispatch} from "react-redux";

import {LoadingButton} from "@mui/lab";

import {useForm} from "react-hook-form";

import FormProvider from "../../../components/hook-form/FormProvider";

import {getExpensesService} from "../../../redux/services/debt_collection_service";

import TypesExpenseList from "./types-expense-list";

import CreateTypeExpenseModal from "../../../sections/dashboard/collection-finance/modal/CreateTypeExpenseModal";
//_________________________________________________________

//* ------- Styles --------

const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};

const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: {xs: "15px", sm: "0px"},
};

const topSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const dividerStyle = {
  mt: "20px",
};

//___________________________________________________________
export default function TypesExpensesPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [OpenModalCreate, setOpenModalCreate] = useState(false);

  const defaultValues = {};

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: {errors},
  } = methods;

  const handleCloseCreate = () => {
    setOpenModalCreate(null);
  };

  useEffect(() => {
    dispatch(getExpensesService({page: 1}));
  }, [dispatch]);

  const onSubmit = (data) => {
  };
  return (
    <>
      <Helmet>
        <title> أنواع المصروفات</title>
      </Helmet>

      {/** App Bar */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <Box
          sx={{
            ...bigFlexBoxStyle,
            flexDirection: {xs: "column", sm: "row"},
          }}
        >
          <Box sx={flexBoxStyle}>
            {" "}
            <img
              src="/assets/icons/arrow-left.svg"
              alt=""
              style={{marginLeft: "10px", cursor: "pointer"}}
              onClick={() => navigate(-1)}
            />
            <Typography sx={lawsuitTextStyle}>أنواع المصاريف </Typography>
          </Box>

          {/** actions */}
          <Box sx={flexBoxStyle}>
            <LoadingButton
              fullWidth
              size="large"
              type="button"
              variant="Contained"
              sx={topSaveButtonStyle}
              onClick={() => {
                setOpenModalCreate(true);
              }}
            >
              إضافة نوع جديد
            </LoadingButton>
            {/** */}
          </Box>
        </Box>

        {/** */}
        <Divider sx={dividerStyle}/>
      </FormProvider>

      <TypesExpenseList/>

      {OpenModalCreate && (
        <CreateTypeExpenseModal
          open={OpenModalCreate}
          onCloseModel={handleCloseCreate}
        />
      )}

    </>
  );
}
