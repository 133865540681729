import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const getClientNeighbors = createAsyncThunk(
  "general/getClientNeighbors",
  async (data, thunkAPI) => {
    try {
      return await axios.get(`/api/debt_collection/clients/${data?.id}/get-prev-and-next-clients/`);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);