import {Helmet} from "react-helmet-async";
import React, {useEffect, useState} from "react";
import {Box, Typography, Button, Divider, Grid, Alert,} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LoadingButton} from "@mui/lab";
import YearCustomDatePicker from "../../../components/custom-datepicker/YearCustomDatePicker";
import {useForm} from "react-hook-form";
import FormProvider from "../../../components/hook-form/FormProvider";
import {toast} from "react-toastify";
import CustodyExpenseModal from "../../../sections/dashboard/collection-finance/modal/CustodyExpenseModal";
import CreateCustodyExpenseModal from "../../../sections/dashboard/collection-finance/modal/CreateCustodyExpenseModal";
import CustudyExpenseList from "./custudy-expense-list";
import {getCustudyExpenseService} from "../../../redux/services/debt_collection_service";
import SummaryCustodyAccordion from "../../../sections/dashboard/collection-finance/accordion/SummaryCustodyAccordion";
import {setCustodyExpensePage, setEmplyeeId, setEmplyeeName} from "../../../redux/slices/deptCollection";
import SelectSingleEmployee from "../../../components/custom-select/SelectSingleEmployee";
import {
  bigFlexBoxStyle,
  dividerStyle,
  flexBoxStyle,
  lawsuitTextStyle,
  changeButtonStyle,
  topSaveButtonStyle,
  topCancleButtonStyle
} from "../../../styles/CustodyExpensesPageStyles";

//___________________________________________________________

export default function CustodyExpensesPage() {

  const dispatch = useDispatch();

  const {
    employeeId,
    employeeName,
    movementDateAfter,
    movementDateBefore,
    custodyExpensePage,
    custodyExpenseLoaded,
  } = useSelector((state) => state.deptCollection);

  const {permissions} = useSelector((state) => state?.users);

  const hasPermissions = permissions?.includes("finance.add_custodyexpense");

  const navigate = useNavigate();

  const [OpenModal, setOpenModal] = useState(false);

  const [OpenModalCreate, setOpenModalCreate] = useState(false);

  const defaultValues = {
    movement_date_before: movementDateBefore,
    movement_date_after: movementDateAfter,
  };

  const methods = useForm({defaultValues});

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: {errors},
  } = methods;

  const handleClose = () => {
    setOpenModal(null);
    if (!employeeId)
      navigate(-1);
  };

  const handleCloseCreate = () => {
    setOpenModalCreate(null);
  };

  useEffect(() => {
    setOpenModal(true);
  }, []);

  useEffect(() => {
    if (!custodyExpenseLoaded)
      dispatch(
        getCustudyExpenseService({
          id: employeeId,
          movement_date_before: movementDateBefore,
          movement_date_after: movementDateAfter,
          page: custodyExpensePage
        })
      );
  }, [custodyExpensePage, custodyExpenseLoaded]);

  const onSubmit = (data) => {
    if (data.employee) {
      dispatch(
        getCustudyExpenseService({
          id: data.employee,
          movement_date_before: data.movement_date_before,
          movement_date_after: data.movement_date_after,
          page: custodyExpensePage
        })
      );
    } else {
      toast.error("يجب اختيار موظف");
    }
  };

  useEffect(() => {
    dispatch(setCustodyExpensePage(1));
    reset({
      movement_date_before: movementDateBefore,
      movement_date_after: movementDateAfter,
      employee: employeeId,
    });

  }, [movementDateBefore, movementDateAfter, employeeId]);

  function handleChangeEmployee(value) {
    setValue("employee", value?.id);
    dispatch(setEmplyeeId(value?.id));
    dispatch(setEmplyeeName(value?.first_name));
  }

  return (
    <>
      <Helmet>
        <title> عهدة المصروفات</title>
      </Helmet>

      {/** App Bar */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <Box
          sx={{
            ...bigFlexBoxStyle,
            flexDirection: {xs: "column", sm: "row"},
          }}
        >
          <Box sx={flexBoxStyle}>
            <img
              src={"/assets/icons/arrow-left.svg"}
              alt="back"
              style={{marginLeft: "10px", cursor: "pointer"}}
              onClick={() => navigate(-1)}
            />
            <Typography sx={lawsuitTextStyle}>عهدة المصروفات</Typography>
            <Typography sx={{...lawsuitTextStyle, ml: "10px", mr: "10px"}}>
              ({employeeName ?? ""})
            </Typography>
          </Box>

          {/** actions */}
          <Box sx={flexBoxStyle}>
            {hasPermissions && (
              <LoadingButton
                fullWidth
                size="large"
                type="button"
                variant="Contained"
                sx={topSaveButtonStyle}
                onClick={() => {
                  setOpenModalCreate(true);
                }}
              >
                صرف عهدة{" "}
              </LoadingButton>
            )}

            {/** */}
            <Button
              fullWidth
              size="large"
              variant="Contained"
              sx={topCancleButtonStyle}
            >
              إصدار تقرير
            </Button>
          </Box>
        </Box>
        {/** */}
        <Divider sx={dividerStyle}/>

        <Grid container columnSpacing={2} paddingTop={"10px"}>
          <Grid item xs={12}>
            {/** List */}
            <Box sx={flexBoxStyle} width="70%" gap="1em" my={2}>
              <YearCustomDatePicker
                name="movement_date_after"
                control={control}
                setValue={setValue}
                defaultDate={movementDateAfter}
              />

              <YearCustomDatePicker
                name="movement_date_before"
                control={control}
                setValue={setValue}
                defaultDate={movementDateBefore}
              />

              <SelectSingleEmployee
                name={"employee"}
                handleChange={handleChangeEmployee}
                value={employeeId}
              />

              <Button
                fullWidth
                size="large"
                color="primary"
                type="submit"
                sx={changeButtonStyle}
                onClick={handleSubmit(onSubmit)}
              >
                تغيير
              </Button>
            </Box>
          </Grid>

          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={9}>
              <CustudyExpenseList/>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <SummaryCustodyAccordion/>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>

      {OpenModal && (
        <CustodyExpenseModal open={OpenModal} onCloseModel={handleClose}/>
      )}

      {OpenModalCreate && (
        <CreateCustodyExpenseModal
          open={OpenModalCreate}
          onCloseModel={handleCloseCreate}
        />
      )}
    </>
  );
}
