import {styled} from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";

export const CustomizedAccordion = styled(Accordion)(() => ({
  "&.Mui-expanded": {
    boxShadow: "none",
    margin: "0px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    //margin: 0,
  },
  ".MuiButtonBase-root": {
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));

export const boxAccordionTitleStyle = {
  bgcolor: "#6A8490",
  color: "#FEFEFE",
  fontSize: "22px",
  fontWeight: 500,
};

export const bodyOfAccordionStyle = {
  bgcolor: "#CCDDE4",
  paddingTop: "20px",
};

export const lableStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "rgba(47, 43, 44, 1)",
  //pb: "8px",
  lineHeight: "20px",
};

export const MainSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#97BDCE",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  textAlign: "center",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#1A141F",
  paddingBottom: "4px",
};

export const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",
  mb: "8px",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

export const bigFlexBoxStyle = {
  display: "flex",
  gap: "0.5em",
  alignItems: "center",
};

export const dateNameStyle = {
  fontSize: "16px",
  fontWeight: 600,
  color: "rgba(47, 43, 44, 1)",
  lineHeight: "28px",
};

export const readOnluStyle = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#818181",
  lineHeight: "28px",
};

export const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: {xs: "15px", sm: "0px"},
};

export const loadingButtonStyle = {
  borderRadius: "5px",
  width: "268px",
  fontSize: "16px",
  height: "40px",
  fontWeight: 400,
  //bgcolor: "#97BDCE",
  color: "#6A8490",
  border: "2px solid #6A8490",
  paddingLeft: "18px",
  paddingright: "18px",
  paddingTop: "10px",
  paddingBottom: "10px",
  textAlign: "center",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const multiRowBorderStyle = {
  width: "100%",

  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    //height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

// --- modal ---

export const checkBoxConatainerStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
};

export const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: {xs: "95%", sm: "543px", md: "60%", lg: "50%"},
  bgcolor: "#CCDDE4",
  pt: {xs: "60px", md: "24px", lg: "30px"},
  pb: {xs: "40px", md: "24px", lg: "30px"},
  px: {xs: "10px", sm: "20px", md: "20px", lg: "30px"},
  height: "auto",
};

export const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

export const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};

export const lableCheckBoxStyle = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#2F2B2C",
};

export const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

export const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "30px",
};

export const boxOfModalStyle = {position: "absolute", top: "22px", left: "22px"};

export const saveButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "#426372",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

