import React, {useState} from "react";

import {
  Box,
  Modal,
  Typography,
  Backdrop,
  Alert,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";

import FormProvider, {RHFTextField} from "../../../../components/hook-form";

import {useForm} from "react-hook-form";

import {LoadingButton} from "@mui/lab";

import {useDispatch, useSelector} from "react-redux";

import {updateOpponentsDependencisService,} from "../../../../redux/services/debt_collection_service";

import {toast} from "react-toastify";

import {multiRowBorderStyle} from "../../../../styles/MainInformationFormStyles";

// -----------------------------------------------------------------------------

// ----------- Styles --------------
const checkBoxConatainerStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
};

const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: {xs: "95%", sm: "543px", md: "60%", lg: "60%"},
  bgcolor: "#CCDDE4",
  pt: {xs: "60px", md: "24px", lg: "30px"},
  pb: {xs: "40px", md: "24px", lg: "30px"},
  px: {xs: "10px", sm: "20px", md: "20px", lg: "30px"},
  height: "auto",
};
const lableStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  lineHeight: "20px",
};
const txtFieldBorderStyle = {
  borderRadius: "5px",
  width: "100%",
  boxShadow: "#101828",

  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",

    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};
const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};
const lableCheckBoxStyle = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#2F2B2C",
};
const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "space-between",
  mt: "30px",
};

const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  paddingBottom: "4px",
  paddingTop: "10px",
};

const boxOfModalStyle = {position: "absolute", top: "22px", left: "22px"};

//_______________________________________________________________

export default function EditVoucherModal({open, detailes, onCloseModel}) {
  const dispatch = useDispatch();

  const {opponentId, isLoading} = useSelector(
    (state) => state.deptCollection
  );
  const [checked, setChecked] = useState(detailes?.save_to_contact);

  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setFiles(files);
  };

  const defaultValues = {
    full_name: detailes?.full_name,
    attribute: detailes?.attribute,
    nationality: detailes?.nationality,
    civil_number: detailes?.civil_number,
    phone_number: detailes?.phone_number,
    email: detailes?.email,
    address: detailes?.address,
    save_to_contact: detailes?.save_to_contact,
  };

  const methods = useForm({defaultValues,});

  const {control, handleSubmit, formState: {errors},} = methods;

  const onSubmit = async (data) => {

    const formData = new FormData();

    for (let key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    }
    formData.delete("save_to_contact");
    formData.append("save_to_contact", checked ? 1 : 0);

    const filesArray = Array.from(files || []);
    filesArray.forEach((file, index) => {
      formData.append(`attachments[${index}]file`, file);
    });

    dispatch(
      updateOpponentsDependencisService({
        id: detailes?.id,
        data: formData,
      })
    ).then((response) => {
      if (
        response?.payload?.status === 200 ||
        response?.payload?.status === 201
      ) {
        onCloseModel();
        toast("تم التعديل بنجاح");
        window.location.reload()
      } else {
        response?.payload?.errors?.map((error, index) => toast.warn(error));
      }
    });
  };

  // ----------- JSX Code -------------

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{...ModalSx}}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src="/assets/icons/x.svg" alt="" style={{}}/>
          </Box>

          <Typography sx={modalTitleSx}>تعديل كفيل </Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <Grid container columnSpacing={4} mt={1}>
              <Grid item xs={12} sm={6} md={6} mt={1}>
                <Typography sx={lableStyle}>اسم الكفيل</Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="full_name"
                    control={control}
                    placeholder="ادخل اسم الكفيل"
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={1}>
                <Typography sx={lableStyle}>
                  صفة الكفيل
                </Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="attribute"
                    placeholder="ادخل صفة الكفيل"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6} mt={1}>
                <Typography sx={lableStyle}>الجنسية</Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="nationality"
                    control={control}
                    placeholder="ادخل جنسية الكفيل"
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={1}>
                <Typography sx={lableStyle}>الرقم المدني</Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="civil_number"
                    control={control}
                    placeholder="ادخل الرقم المدني للكفيل"
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6} mt={1}>
                <Typography sx={lableStyle}>رقم الهاتف </Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="phone_number"
                    placeholder="9xxxxxxx"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} mt={1}>
                <Typography sx={lableStyle}>البريد الإلكتروني </Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="email"
                    placeholder="ادخل البريد الإلكتروني"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography sx={lableSx}> العنوان</Typography>
                <RHFTextField
                  name="address"
                  control={control}
                  placeholder="ادخل عنوان الكفيل"
                  multiline
                  rows={3}
                  type={"text"}
                  sx={{
                    ...multiRowBorderStyle,
                    "& .MuiOutlinedInput-input": {
                      width: "100%",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} mt={1}>
                {/** upload attachment */}
                <Box>
                  <Typography sx={lableSx}>المرفقات</Typography>
                  <RHFTextField
                    name="attachments"
                    control={control}
                    type="file"
                    InputLabelProps={{shrink: true}}
                    sx={{
                      ...txtFieldBorderStyle,
                      width: "100%",
                      marginRight: "12px",
                    }}
                    onChange={handleFileChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={checkBoxConatainerStyle}>
                  <Checkbox
                    sx={{...checkBoxSx}}
                    checked={checked}
                    onClick={() => {
                      setChecked(!checked);
                    }}
                  />
                  <Typography sx={lableCheckBoxStyle}>
                    تسجيل في جهات الاتصال
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/** actions Button */}
            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                onClick={handleSubmit(onSubmit)}
                variant="Contained"
                sx={loadingButtonStyle}
                loading={isLoading}
              >
                حفظ
              </LoadingButton>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="button"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
