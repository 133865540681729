import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Box, Pagination,} from "@mui/material";
import TableNoData from "../../../../../components/table/TableNoData";
import DataTable from "../../../../../components/table/DataTable";
import {StyledTableContainer, paginationSx, centerFlexBox} from "../../../../../styles/PaymentsListStyles";

function PaymentsList({data, page, setPage, count, setDataLoaded}) {
  const [table, setTable] = useState({});

  const handlePagination = (value) => {
    setPage(value);
    setDataLoaded(false);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setTable({
        columns: [
          {
            title: "رقم الملف", accessor: "file_number",
            cell: ({row}) => {
              return row?.opponent_object?.file_number;
            },
          },
          {
            title: "اسم الخصم", accessor: "full_name",
            cell: ({row}) => {
              return row?.opponent_object?.full_name;
            },
          },
          {title: "نوع السداد", accessor: "type"},
          {title: "قيمة السداد", accessor: "value"},
          {title: "طريقة السداد", accessor: "method"},
          {title: "التاريخ", accessor: "payment_date"},
          {title: "الموظف", accessor: "employee",
            cell: ({row}) => {
              return row?.employee_object?.first_name + " " + row?.employee_object?.last_name;
            },
          },
        ],
        rows: data,
      });
    }
  }, [data]);

  return (
    <Box mt={2}>
      <StyledTableContainer>
        <Box mb={2}>
          {data?.length > 0 ? (
            <>
              <DataTable table={table}/>
              <Box sx={paginationSx}>
                <Pagination
                  count={count}
                  page={page}
                  onChange={(event, value) => handlePagination(value)}
                  color="primary"
                />
              </Box>
            </>
          ) : (
            <Box sx={centerFlexBox}>
              <TableNoData isNotFound/>
            </Box>
          )}
        </Box>
      </StyledTableContainer>
    </Box>
  );
}

PaymentsList.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setDataLoaded: PropTypes.func.isRequired,
};

export default PaymentsList;
