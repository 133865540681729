import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function DataTableBodyCell({noBorder, children}) {
  return (
    <Box
      component="td"
      textAlign={"center"}
      py={1.5}
      px={3}
      sx={{borderBottom: noBorder ? 'none' : `1px solid #E0E0E0`}}
    >
      <Box
        display="inline-block"
        width="max-content"
        color="text"
        sx={{verticalAlign: 'middle'}}
      >
        {children}
      </Box>
    </Box>
  );
}

DataTableBodyCell.defaultProps = {
  noBorder: false,
};

DataTableBodyCell.propTypes = {
  noBorder: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default DataTableBodyCell;
