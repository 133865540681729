export const clientsColumnsList = [
  {id: "-1", name: "المسلسل", status: true},
  {id: "0", name: "الاسم", status: true},
  {id: "1", name: "رقم الهاتف", status: true},
  {id: "5", name: "البريد الالكتروني", status: true},
  {id: "2", name: "عدد القضايا", status: true},
  {id: "3", name: " الرصيد(KD)", status: true},
  {id: "4", name: "تاريخ الإضافة", status: true},
];

export const proceduresColumnsList = [
  {id: "0", name: "نوع الاجراء", status: true},
  {id: "1", name: "تاريخ الاجراء", status: true},
  {id: "2", name: "بيان الافادة", status: true},
  {id: "3", name: " الاجراء القادم", status: true},
  {id: "4", name: "رقم الاتصال", status: true},
  {id: "5", name: "الموظف", status: true},
];

export const CourtSessionColumnsList = [
  {id: "0", name: "الاجراء", status: true},
  {id: "1", name: "تاريخ الاجراء", status: true},
  {id: "2", name: "تاريخ المراجعة", status: true},
  {id: "3", name: " الموظف المختص", status: true},
  {id: "4", name: " الموظف البديل", status: true},
  {id: "5", name: "نوع الجلسة", status: true},
  {id: "6", name: "بيان الاجراء", status: true},
];

export const ExpertsSessionColumnsList = [
  {id: "0", name: "الاجراء", status: true},
  {id: "1", name: "تاريخ الاجراء", status: true},
  {id: "2", name: "تاريخ المراجعة", status: true},
  {id: "3", name: " الخبراء", status: true},
  {id: "4", name: " الموظف المختص", status: true},
  {id: "5", name: "نوع الجلسة", status: true},
  {id: "6", name: "بيان الاجراء", status: true},
];

export const AdministrativeSessionColumnsList = [
  {id: "-1", name: "المسلسل", status: true},
  {id: "0", name: "الاجراء", status: true},
  {id: "1", name: "تاريخ الاجراء", status: true},
  {id: "2", name: "إنهاء الاجراء", status: true},
  {id: "3", name: " الموظف المختص", status: true},
  {id: "4", name: "نوع الاجراء", status: true},
  {id: "5", name: "حالة الإنجاز", status: true},
  {id: "6", name: "بيان الاجراء", status: true},
];

export const ExcuteSessionColumnsList = [
  {id: "-1", name: "المسلسل", status: true},
  {id: "0", name: "الاجراء", status: true},
  {id: "1", name: "انهاء المراجعة", status: true},
  {id: "2", name: "إجراء الإفادة", status: true},
  {id: "3", name: " رقم الاتصال", status: true},
  {id: "4", name: " حالة الإنجاز", status: true},
  {id: "5", name: "بيان الاجراء", status: true},
];

export const AttachmentsSessionColumnsList = [
  {id: "0", name: "اسم الملف", status: true},
  {id: "1", name: "مضاف من قبل", status: true},
  {id: "2", name: "نوع المرفق", status: true},
  {id: "3", name: "تاريخ المرفق", status: true},
  {id: "4", name: " تاريخ الإضافة", status: true},
];

export const collClientsColumnsList = [
  {id: "-1", name: "المسلسل", status: true},
  {id: "0", name: "الاسم", status: true},
  {id: "1", name: "رقم الهاتف", status: true},
  {id: "2", name: " الملاحظة", status: true},
  {id: "3", name: " الايميل", status: true},
];

export const oppColumnsList = [
  {id: "0", name: "رقم الملف", status: true},
  {id: "1", name: "رقم العقد", status: true},
  {id: "2", name: "اسم الخصم", status: true},
  {id: "3", name: "حالة الملف", status: true},
  {id: "4", name: "مرحلة العمل", status: true},
  {id: "5", name: "رقم الهاتف", status: true},
  {id: "6", name: "قيمة المطالبة", status: true},
  {id: "7", name: "المبلغ المسدد", status: true},
  {id: "8", name: "الإسناد", status: true},
  {id: "9", name: "تاريخ الورود", status: false},
  {id: "10", name: "محكمة التنفيذ", status: false},
  {id: "11", name: "سبب التنفيذ", status: false},
  {id: "12", name: "قيمة رسم الأداء", status: false},
  {id: "13", name: "رقم أمر الأداء", status: false},
  {id: "14", name: "المصروفات", status: false},
  {id: "15", name: "إجمالي المطالبة", status: false},
  {id: "16", name: "إجمالي الباقي", status: false},
  {id: "17", name: "الرقم الآلي", status: false},
  {id: "18", name: "العنوان", status: false},
  {id: "19", name: "المحافظة", status: false},
  {id: "20", name: "المدينة", status: false},
  {id: "21", name: "الجنسية", status: false},
  {id: "22", name: "الرقم المدني", status: false},
  {id: "23", name: "الرقم المفصول", status: false},
  {id: "24", name: "تاريخ نزول المحكمة", status: false},
  {id: "25", name: "الموكل", status: false},
  {id: "26", name: "الملفات المكررة", status: true},
  {id: "27", name: "جهة العمل", status: false},
  {id: "28", name: "الرقم الآلي المرفوض 1", status: false},
  {id: "29", name: "الرقم الآلي المرفوض 2", status: false},
  {id: "30", name: "تاريخ التسليم للمحكمة", status: false},
  {id: "31", name: "تاريخ البعثة البريدية", status: false},
  {id: "32", name: "تاريخ التسليم للتنفيذ", status: false},
  {id: "33", name: "تاريخ أمر منع السفر", status: false},
  {id: "34", name: "تاريخ السند الإذني", status: false},
  {id: "35", name: "قيمة السند الإذني", status: false},
  {id: "36", name: "تاريخ الإعلان", status: false},
  {id: "37", name: "تاريخ الحكم", status: false},
  {id: "38", name: "تاريخ الترسيم", status: false},
];

export const invoicesColumnsList = [
  {id: "-1", name: "المسلسل", status: true},

  {id: "0", name: "الموكل ", status: true},
  {id: "1", name: "القيمة  ", status: true},
  {id: "2", name: "حالة الفاتورة", status: true},
  {id: "3", name: "الإصدار ", status: true},
];
export const voucherColumnsList = [
  {id: "-1", name: "المسلسل", status: true},
  {id: "0", name: "الموكل ", status: true},
  {id: "1", name: "القيمة  ", status: true},
  {id: "2", name: "الرقم الآلي", status: true},
  {id: "3", name: " تاريخ الإصدار", status: true},
];

export const reportsColumnsList = [
  {id: "0", name: "الموكل", status: true},
  {id: "1", name: "موضوع الدعوى", status: true},
  {id: "2", name: "المستشار", status: true},
  {id: "3", name: "الرقم الآلي", status: true},
  {id: "4", name: "المحكمة", status: true},
  {id: "5", name: "الإجراء", status: true},
  {id: "6", name: "بيان الإجراء", status: true},
  {id: "7", name: "الجلسة القادمة", status: true},
  {id: "8", name: "المحامي", status: true},
];

export const usersColumnsList = [
  {id: "0", name: "الاسم", status: true},
  {id: "1", name: " المجموعة", status: true},
  {id: "2", name: "رقم الهاتف", status: true},
  {id: "3", name: "البريد الالكتروني", status: true},
  {id: "4", name: "ساعات العمل", status: true},
];
export const empsColumnsList = [
  {id: "0", name: "الاسم", status: true},
  {id: "1", name: "الوظيفة ", status: true},
  {id: "2", name: "رقم الهاتف", status: true},
  {id: "3", name: "البريد الالكتروني", status: true},
  {id: "4", name: "الصورة الشخصية", status: true},
];

export const SessionsColumnsList = [
  {id: "0", name: "الإجراء", status: true},
  {id: "1", name: "تاريخ الإجراء", status: true},
  {id: "2", name: "تاريخ المراجعة", status: true},
  {id: "3", name: "الموكل", status: true},
  {id: "4", name: "الرقم الآلي", status: true},
  {id: "5", name: "الإسناد", status: true},
  {id: "6", name: "نوع الجلسة", status: true},
];

export const AccountsColumnsList = [
  {id: "0", name: "الموكل", status: true},
  {id: "1", name: "الرقم الآلي", status: true},
  {id: "2", name: "العملية", status: true},
  {id: "3", name: "التاريخ", status: true},
  {id: "4", name: "القيمة", status: true},
  {id: "5", name: "حالة الدفع", status: true},
];

export const genralExpenseColumnsList = [
  {id: "-1", name: "المصروف", status: true},
  {id: "0", name: "التاريخ ", status: true},
  {id: "1", name: "من قبل  ", status: true},
  {id: "2", name: "القيمة", status: true},
  {id: "3", name: "الوصف ", status: true},
];

export const fileColumnsList = [
  {id: "0", name: "رقم الملف", status: true, key: "file_number"},
  {id: "1", name: "رقم العقد", status: false, key: "contract_number"},
  {id: "2", name: "اسم الخصم", status: false, key: "full_name"},
  {id: "3", name: "حالة الملف", status: false, key: "file_status"},
  {id: "4", name: "تاريخ الورود", status: false, key: "entry_date"},
  {id: "5", name: "مرحلة العمل", status: false, key: "action_stage"},
  {id: "6", name: "قيمة المطالبة", status: false, key: "claim_value"},
  {id: "7", name: "محكمة التنفيذ", status: false, key: "execution_court"},
  {id: "8", name: "سبب التنفيذ", status: false, key: "reason_for_execution"},
  {id: "9", name: "قيمة رسم الأداء", status: false, key: "performance_fee_value"},
  {id: "10", name: "رقم أمر الأداء", status: false, key: "performance_number_value"},
  {id: "11", name: "الرقم الآلي", status: false, key: "automatic_number"},
  {id: "12", name: "العنوان", status: false, key: "address"},
  {id: "13", name: "الموكل", status: false, key: "client"},
  {id: "14", name: "الجنسية", status: false, key: "nationality"},
  {id: "15", name: "الرقم المدني", status: false, key: "civil_number"},
  {id: "16", name: "المدينة", status: false, key: "city"},
  {id: "17", name: "المحافظة", status: false, key: "province"},
  {id: "18", name: "الرقم المفصول", status: false, key: "separated_number"},
  {id: "19", name: "تاريخ نزول المحكمة", status: false, key: "date_of_court_filing"},
  {id: "20", name: "رقم الهاتف", status: false, key: "phone_numbers"},
  {id: "21", name: "الإسناد", status: false, key: "assigned_employee"},
  {id: "22", name: "المصروفات", status: false, key: "expenses"},
  {id: "23", name: "إجمالي المطالبة", status: false, key: "total_claim"},
  {id: "24", name: "المبلغ المسدد", status: false, key: "amount_paid"},
  {id: "25", name: "إجمالي الباقي", status: false, key: "total_remainder"},
  {id: "26", name: "البريد الالكتروني", status: false, key: "email"},
  {id: "27", name: "الملفات المكررة", status: false, key: "duplicate_files"},
  {id: "28", name: "جهة العمل", status: false, key: "employer"},
  {id: "29", name: "الرقم الآلي المرفوض 1", status: false, key: "rejected_automatic_number_1"},
  {id: "30", name: "الرقم الآلي المرفوض 2", status: false, key: "rejected_automatic_number_2"},
  {id: "31", name: "تاريخ التسليم للمحكمة", status: false, key: "date_of_delivery_to_court"},
  {id: "32", name: "تاريخ البعثة البريدية", status: false, key: "date_of_mailing"},
  {id: "33", name: "تاريخ التسليم للتنفيذ", status: false, key: "date_of_delivery_for_execution"},
  {id: "34", name: "تاريخ أمر منع السفر", status: false, key: "date_of_travel_ban_order"},
  {id: "35", name: "تاريخ السند الإذني", status: false, key: "date_of_promissory_note"},
  {id: "36", name: "قيمة السند الإذني", status: false, key: "value_of_promissory_note"},
  {id: "37", name: "تاريخ الإعلان", status: false, key: "date_of_advertisement"},
  {id: "38", name: "تاريخ الحكم", status: false, key: "date_of_judgment"},
  {id: "39", name: "تاريخ الترسيم", status: false, key: "date_of_demarcation"},
];

export const PaymentsFileColumnsList = [
  {id: "0", name: "رقم الملف", key: "file_number"},
  {id: "1", name: "اسم الخصم", key: "full_name"},
  {id: "2", name: "نوع السداد", key: "type"},
  {id: "3", name: "قيمة السداد", key: "value"},
  {id: "4", name: "طريقة السداد", key: "method"},
  {id: "5", name: "التاريخ", key: "payment_date"},
  {id: "6", name: "الموظف", key: "employee"},
];
