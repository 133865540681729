import React from "react";

import {Box, Grid, Typography} from "@mui/material";

import MainInformationForm from "../../accordion/MainInformationForm";

import FinanceAccordion from "../../accordion/FinanceAccordion";

import ContactInformation from "../../accordion/ContactInformation";

import OppDetailsSkeletion from "../../../../../components/skeleton/OppDetailsSkeletion";

import Scrollbar from "../../../../../components/scrollbar/Scrollbar";

import DataTable from "../../../../../components/table/DataTable";

import {useDispatch, useSelector} from "react-redux";

import {setOppenentId} from "../../../../../redux/slices/deptCollection";

import {useNavigate} from "react-router-dom";

// -----------------------------------------------------------------------------

export default function DetailsTab(
  {
    setValue,
    control,
    reset,
    watch,
    methods,
    handleSubmit,
    onSubmit,
    defaultValues,
  }) {

  const {OppDetails, isLoadingOppDetails,} = useSelector((state) => state.deptCollection);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onClickFileRow = (row) => {
    dispatch(setOppenentId(row?.id));
    navigate(`/dashboard/collection/opponent/`);
  };

  // ------------- JSX Code ----------

  return (
    <>
      {isLoadingOppDetails ? (
        <OppDetailsSkeletion/>
      ) : (
        <Box sx={{pt: "40px"}}>
          <Grid container columnSpacing={10}>
            {/** المعلومات الاساسية */}
            <Grid item xs={12} sm={12} md={8}>
              <MainInformationForm
                setValue={setValue}
                control={control}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                reset={reset}
                watch={watch}
                defaultValues={defaultValues}
              />
            </Grid>

            {/** الماليات && ملعومات التواصل */}
            <Grid item xs={12} sm={12} md={4}>
              <Box mb={2}>
                <FinanceAccordion/>
              </Box>

              <Box bgcolor={"white"} ml={-6} border={"1px solid"} borderColor={"#ccdde4"}>
                <Scrollbar p={1} mt={1}>
                  <Typography sx={{color: "#6A8490"}} fontWeight={"bold"} textAlign={"center"}>
                    الملفات المكررة
                  </Typography>
                  {OppDetails?.duplicate_files && OppDetails.duplicate_files?.length > 0
                    ? <DataTable
                      table={{
                        columns: [
                          {title: "رقم الملف", accessor: "file_number",},
                          {title: "اسم الخصم", accessor: "full_name"},
                          {
                            title: "اسم العميل", accessor: "client",
                            cell: ({row}) => {
                              return row?.client?.full_name;
                            },
                          },
                        ],
                        rows: OppDetails?.duplicate_files
                      }}
                      //simpleStyle={true}
                      onClickRow={onClickFileRow}
                    />
                    : <Typography color={"primary"} textAlign={"center"}>
                      لا يوجد ملفات مكررة
                    </Typography>
                  }
                </Scrollbar>
              </Box>
              {/* <ContactInformation
                setValue={setValue}
                control={control}
                watch={watch}
                methods={methods}
              /> */}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
