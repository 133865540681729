import PropTypes from "prop-types";
import {Box, MenuItem, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {autocompleteSx, selectBoxSx} from "../../styles/AssignEmployeeStyles";
import ListItemText from "@mui/material/ListItemText";
import LoadMoreButton from "../../utils/LoadMoreButton";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getEmployeeService} from "../../redux/services/debt_collection_service";

function SelectSingleEmployee({name, handleChange, value}) {
  const {isLoadingEmployees, empNext, empLastPage, employees} = useSelector((state) => state.deptCollection);

  const [employeesList, setEmployeesList] = useState(employees || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const getIdsParam = () => {
    if (!value) return [];
    return (typeof value === 'object' && value.id) ? [value.id] :
      (typeof value === 'number') ? [value] : [];
  };

  useEffect(() => {
    if (employeesList.length === 0 && employees?.length > 0) {
      setEmployeesList(employees);
    }
  }, [employees, employeesList.length]);

  useEffect(() => {

    const fetchEmployees = async () => {
      const res = await dispatch(getEmployeeService({
        page: 1,
        search_value: searchValue,
        ids: getIdsParam()
      }));
      setEmployeesList(res.payload.data.data);
      setCurrentPage(1);
    };

    fetchEmployees();
  }, [searchValue, dispatch, value]);

  const handleLoadMoreEmployees = async (nextPage) => {
    const res = await dispatch(getEmployeeService({
      page: nextPage,
      search_value: searchValue,
      ids: getIdsParam()
    }));
    setEmployeesList((prev) => [...prev, ...res.payload?.data?.data]);
    setCurrentPage(nextPage);
  };

  const searchHandlerEmployee = (e) => {
    const search_value = e.target.value;
    setSearchValue(search_value);
  };

  const handleChangeOption = (event, newValue) => {
    setSearchValue("");
    handleChange(newValue);
  };

  const convertValueToSelected = useCallback((value) => {
    if (typeof value === 'object') {
      return value;
    } else if (typeof value === 'number') {
      return employeesList.find((item) => item.id === value);
    }
  }, [employeesList]);

  const initialValue = useMemo(() => {
    return convertValueToSelected(value);
  }, [value, convertValueToSelected]);

  return (
    <Box sx={selectBoxSx}>
      <Autocomplete
        name={name}
        options={employeesList}
        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={handleChangeOption}
        value={initialValue}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        sx={autocompleteSx}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="ابحث عن الموظف"
            onChange={searchHandlerEmployee}
            InputProps={{
              ...params.InputProps,
              style: {height: 'auto'},
            }}
          />
        )}
        renderOption={(props, option, {selected}) => (
          <MenuItem {...props} key={option?.id} selected={selected}>
            <ListItemText primary={`${option?.first_name} ${option?.last_name}`}/>
          </MenuItem>
        )}
        ListboxComponent={(props) => (
          <ul {...props}>
            {props.children}
            {empNext && (
              <LoadMoreButton
                lastPage={empLastPage}
                onLoadMore={handleLoadMoreEmployees}
                loading={isLoadingEmployees}
                activePage={currentPage}
              />
            )}
          </ul>
        )}
      />
    </Box>
  );
}

SelectSingleEmployee.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  handleChange: PropTypes.func.isRequired,
};

export default SelectSingleEmployee;
