import React, {useState, useEffect} from "react";

import {Box, Typography, Grid, MenuItem, IconButton} from "@mui/material";

// form
import {RHFTextField, RHFSelect} from "../../../../components/hook-form";

import AccordionSummary from "@mui/material/AccordionSummary";

import AccordionDetails from "@mui/material/AccordionDetails";

import {LoadingButton} from "@mui/lab";

import {useDispatch, useSelector} from "react-redux";

import useClientSearch from "../../../../hooks/useClientSearch";

import {getAllClientsService} from "../../../../redux/services/general_service";

import {exportAssignmentService} from "../../../../redux/services/debt_collection_service";

import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";

import {useNavigate} from "react-router-dom";

import AddVoucherModal from "../modal/AddVoucherModal";

import EditVoucherModal from "../modal/EditVoucherModal";

// --- styles
import {
  CustomizedAccordion,
  boxAccordionTitleStyle,
  bodyOfAccordionStyle,
  lableStyle,
  MainSaveButtonStyle,
  lableSx,
  txtFieldBorderStyle,
  bigFlexBoxStyle,
  dateNameStyle,
  readOnluStyle,
  flexBoxStyle,
  loadingButtonStyle,
  multiRowBorderStyle
} from "../../../../styles/MainInformationFormStyles";
import {Add, AddBox, AddOutlined, Edit} from "@mui/icons-material";

//___________________________________________________________

export default function MainInformationForm({setValue, control, handleSubmit, onSubmit, watch,}) {
  const dispatch = useDispatch();

  const {courtTable, stageTable, fileTable} = useSelector(
    (state) => state.deptCollection
  );

  const {
    OppDetails,
    fileStatus,
    actionStage,
    isLoadingExport,
    opponentId,
    isLoadingOppUpdate,
  } = useSelector((state) => state?.deptCollection);

  const [selectedFileStatus, setSelectedFileStatus] = useState("");

  const [selectedAction, setSelectedActionStage] = useState("");

  const [selectedCourt, setSelectedCourt] = useState("");

  const {clients} = useSelector((state) => state.general);

  const {courts} = useSelector((state) => state.matter);

  const {filteredClients} = useClientSearch(clients);

  const [selectedClient, setSelectedClient] = useState({});

  const [expanded, setIsExpanded] = useState(true);

  const itemsData = [
    {
      name: "reason_for_execution",
      label: "سبب التنفيذ",
      placeholder: "أدخل سبب التنفيذ",
      componentType: "textField"
    },
    {
      name: "separated_number",
      label: "الرقم المفصول",
      placeholder: "أدخل الرقم المفصول",
      componentType: "textField"
    },
    {
      name: "performance_number_value",
      label: "رقم أمر الأداء",
      placeholder: "XXXXXXX",
      componentType: "textField",
      type: "number"
    },
    {
      name: "automatic_number",
      label: "الرقم الآلي",
      placeholder: "XXXXXXX0",
      componentType: "textField"
    },
    {
      name: "date_of_court_filing",
      label: "تاريخ نزول المحكمة",
      componentType: "datePicker"
    },
    {
      name: "batch_number",
      label: "رقم الباتش",
      placeholder: "رقم الباتش",
      componentType: "textField"
    },
    {
      name: "employer",
      label: "جهة العمل",
      placeholder: "جهة العمل",
      componentType: "textField"
    },
    {
      name: "rejected_automatic_number_1",
      label: "الرقم الآلي المرفوض 1",
      placeholder: "الرقم الآلي المرفوض 1",
      componentType: "textField"
    },
    {
      name: "rejected_automatic_number_2",
      label: "الرقم الآلي المرفوض 2",
      placeholder: "الرقم الآلي المرفوض 2",
      componentType: "textField"
    },
    {
      name: "date_of_delivery_to_court",
      label: "تاريخ التسليم للمحكمه",
      componentType: "datePicker"
    },
    {
      name: "date_of_mailing",
      label: "تاريخ البعيثة البريدية",
      componentType: "datePicker"
    },
    {
      name: "date_of_delivery_for_execution",
      label: "تاريخ التسليم للتنفيذ",
      componentType: "datePicker"
    },
    {
      name: "date_of_travel_ban_order",
      label: "تاريخ امر منع السفر",
      componentType: "datePicker"
    },
    {
      name: "date_of_promissory_note",
      label: "تاريخ السند الإذني",
      componentType: "datePicker"
    },
    {
      name: "value_of_promissory_note",
      label: "قيمة السند الإذني",
      placeholder: "قيمة السند الإذني",
      componentType: "textField"
    },
    {
      name: "date_of_advertisement",
      label: "تاريخ الإعلان",
      componentType: "datePicker"
    },
    {
      name: "date_of_judgment",
      label: "تاريخ الحكم",
      componentType: "datePicker"
    },
    {
      name: "date_of_demarcation",
      label: "تاريخ الترسيم",
      componentType: "datePicker"
    }
  ];

  // =================================================================

  const [phones, setPhones] = useState([...Array(1)]);

  const phoneNumbers = watch("phone_numbers") || [];

  const addNewPhoneHandler = () => {
    setValue("phone_numbers", [...phoneNumbers, {phone_number: ""}]);
  };

  const removePhoneHandler = (index) => {
    const updatedPhones = phoneNumbers.filter((_, i) => i !== index);
    setValue(
      "phone_numbers",
      updatedPhones.length > 0 ? updatedPhones : [{phone_number: ""}]
    );
  };

  useEffect(() => {
    setPhones(phoneNumbers);
  }, [phoneNumbers]);

  // region dependencies --------------------------------------

  const [openModal, setOpenModal] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseEdit = () => {
    setOpenEditModal(false);
  };

  const depDetails = [
    {
      label: "الاسم:",
      value: OppDetails?.opponent_dependencies?.full_name
    },
    {
      label: "الصفة:",
      value: OppDetails?.opponent_dependencies?.attribute ?? "",
    },
    {
      label: "جنسية الكفيل:",
      value: OppDetails?.opponent_dependencies?.nationality,
    },
    {
      label: "الرقم المدني للكفيل:",
      value: OppDetails?.opponent_dependencies?.civil_number,
    },
    {
      label: "هاتف الكفيل:",
      value: OppDetails?.opponent_dependencies?.phone_number,
    },
    {
      label: "البريد الالكتروني للكفيل:",
      value: OppDetails?.opponent_dependencies?.email,
    },
    {
      label: "عنوان الكفيل:",
      value: OppDetails?.opponent_dependencies?.address,
    },
  ];

  // endregion --------------------------------------

  //region 1 --------------------------------------
  const file_status = fileStatus?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  const action_stage = actionStage?.map((item) => ({
    value: item[0],
    label: item[1],
  }));

  const cou = courts?.map((item) => ({
    id: item.id, // Assuming item has an 'id' property
    name: item.name,
  }));

  const handleExportAssignment = () => {
    dispatch(exportAssignmentService({id: opponentId}));
  };

  useEffect(() => {
    dispatch(getAllClientsService({page: 1}));
  }, []);

  useEffect(() => {
    setSelectedClient(OppDetails?.client);
    // setSelectedCourt(courts?.find((c) => c.id === OppDetails?.execution_court));
  }, [OppDetails, filteredClients]);

  useEffect(() => {
    setSelectedCourt(courtTable);
    setSelectedFileStatus(fileTable);
    setSelectedActionStage(stageTable);
  }, [courtTable, fileTable, stageTable, filteredClients]);

  const [daysDifference, setDaysDifference] = useState(0);

  useEffect(() => {
    const calculateDaysDifference = () => {
      const entry = new Date(OppDetails?.entry_date);
      const current = new Date();
      const differenceInTime = current - entry;
      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 3600 * 24)
      );
      setDaysDifference(differenceInDays);
    };

    calculateDaysDifference();
    //console.log("OppDetails?.opponent_dependencies");
    // console.log(OppDetails?.opponent_dependencies);
  }, [OppDetails?.entry_date, openEditModal]);

  //endregion --------------------------------------

  //region 2 --------------------------------------
  // Check if info.file_status exists in file_status
  const fileStatusExists = file_status.some(
    (status) => status.value === fileTable
  );

  // If it doesn't exist, add it as the first item
  const updatedFileStatus = fileStatusExists
    ? file_status
    : [{value: fileTable, label: fileTable}, ...file_status];

  //action_stage
  // Check if info.file_status exists in file_status
  const actionStageExists = action_stage.some(
    (status) => status.value === stageTable
  );

  // If it doesn't exist, add it as the first item
  const updatedActionStageStatus = actionStageExists
    ? action_stage
    : [{value: stageTable, label: stageTable}, ...action_stage];

  //courts
  // Check if info.execution_court exists in cou
  const courtsExists = cou.some((court) => court.name === courtTable);

  // If it doesn't exist, add it as the first item
  const updatedCourtsStatus = courtsExists
    ? cou
    : [{id: null, name: courtTable}, ...cou];

  //endregion --------------------------------------

  // ------------- JSX Code ----------
  return (
    <>
      <CustomizedAccordion expanded={expanded}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          sx={boxAccordionTitleStyle}
        >
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Typography sx={boxAccordionTitleStyle}>معلومات أساسية</Typography>
          </Box>
        </AccordionSummary>

        {/* form */}
        <AccordionDetails sx={bodyOfAccordionStyle}>
          <Grid container columnSpacing={6} mt={2}>
            {/**  الإسناد  */}
            <Grid item xs={12} md={12} mt={2}>
              <Box display="flex" alignItems="center" gap="0.5em">
                <Typography sx={dateNameStyle}>الموكل</Typography>

                {/* </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6}> */}
                <RHFSelect
                  name="client"
                  control={control}
                  value={selectedClient}
                  sx={{
                    ...txtFieldBorderStyle,
                    "& .MuiSelect-select span::before": {
                      content: "'اختر موكلاً'",
                      color: "#697D95",
                      fontWeight: 300,
                      fontSize: "14px",
                    },
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => (
                      <img
                        src="/assets/icons/Icon.svg"
                        alt=""
                        style={{
                          marginRight: "19px",
                          marginLeft: "19px",
                        }}
                      />
                    ),
                  }}
                  displayEmpty
                  onChange={(e) => {
                    setValue("client", e.target.value, {
                      shouldValidate: true,
                    });
                    setSelectedClient(e.target.value);
                  }}
                >
                  {filteredClients?.map((client, index) => (
                    <MenuItem
                      sx={{
                        borderBottom: "1px solid  #E5E0EB",
                      }}
                      key={index}
                      value={client?.id}
                    >
                      {client?.full_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Box>
            </Grid>

            {/* رقم الملف */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Box sx={bigFlexBoxStyle}>
                <Typography sx={dateNameStyle}> رقم الملف:</Typography>
                <Typography sx={readOnluStyle}> للقراءة فقط</Typography>
              </Box>
              <RHFTextField
                name="file_number"
                value={OppDetails?.file_number}
                disabled
                type="text"
                placeholder="أدخل رقم الملف"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
            {/* اسم الخصم */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Typography sx={lableStyle}>اسم الخصم:</Typography>
              <RHFTextField
                name="full_name"
                type="text"
                placeholder="أدخل اسم الخصم"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>

            {/* حالة الملف  */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Typography sx={lableStyle}>حالة الملف</Typography>
              <RHFSelect
                name="file_status"
                control={control}
                value={selectedFileStatus}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "'اختر حالة الملف'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  setSelectedFileStatus(e.target.value);
                  console.log("e.target.value", e.target.value);
                  setValue("file_status", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {updatedFileStatus.map((s, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={s.value}
                  >
                    {s.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
            {/* الجنسية */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Typography sx={lableStyle}>الجنسية</Typography>
              <RHFTextField
                name="nationality"
                // control={control}
                placeholder="أدخل الجنسية"
                type="text"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                }}
              />
            </Grid>

            {/* رقم العقد */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Box sx={bigFlexBoxStyle}>
                <Typography sx={dateNameStyle}>رقم العقد:</Typography>
                <Typography sx={readOnluStyle}> للقراءة فقط</Typography>
              </Box>
              <RHFTextField
                name="contract_number"
                // control={control}
                type="text"
                disabled
                placeholder="رقم العقد"
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                  marginRight: "12px",
                }}
              />
            </Grid>
            {/* تاريخ */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box sx={bigFlexBoxStyle}>
                    <Typography sx={dateNameStyle}>تاريخ الورود:</Typography>
                    <Typography sx={readOnluStyle}> للقراءة فقط</Typography>
                  </Box>
                  <YearCustomDatePicker
                    name="entry_date"
                    disabled
                    setValue={setValue}
                    sx={{...txtFieldBorderStyle}}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={bigFlexBoxStyle}>
                    <Typography sx={dateNameStyle}>عدد الأيام:</Typography>

                    <Typography sx={readOnluStyle}> للقراءة فقط</Typography>
                  </Box>
                  <RHFTextField
                    name=""
                    // control={control}
                    disabled
                    type="text"
                    value={daysDifference}
                    placeholder="أدخل اسم الخصم"
                    sx={{
                      ...txtFieldBorderStyle,
                      width: "100%",
                      marginRight: "12px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* الرقم المدني */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Typography sx={lableStyle}>الرقم المدني</Typography>
              <RHFTextField
                name="civil_number"
                placeholder="أدخل الرقم المدني"
                type={"text"}
                sx={{
                  ...txtFieldBorderStyle,
                  width: "100%",
                }}
              />
            </Grid>
            {/*  محكمة التنفيذ  */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Typography sx={lableStyle}> محكمة التنفيذ</Typography>
              <RHFSelect
                name="execution_court"
                control={control}
                value={selectedCourt}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "'اختر محكمة التنفيذ'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  console.log("e", e.target.value);

                  setSelectedCourt(e.target.value);
                  setValue("execution_court", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {updatedCourtsStatus?.map((court, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={court?.name}
                  >
                    {court.name}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            {/* مرحلة العمل */}
            <Grid item xs={12} sm={12} md={6} mt={2}>
              <Typography sx={lableStyle}>مرحلة العمل</Typography>
              <RHFSelect
                name="action_stage"
                value={selectedAction}
                control={control}
                sx={{
                  ...txtFieldBorderStyle,
                  "& .MuiSelect-select span::before": {
                    content: "' اختر مرحلة العمل'",
                    color: "#697D95",
                    fontWeight: 300,
                    fontSize: "14px",
                  },
                }}
                SelectProps={{
                  native: false,
                  IconComponent: () => (
                    <img
                      src="/assets/icons/Icon.svg"
                      alt=""
                      style={{
                        marginRight: "19px",
                        marginLeft: "19px",
                      }}
                    />
                  ),
                }}
                displayEmpty
                onChange={(e) => {
                  setSelectedActionStage(e.target.value);
                  setValue("action_stage", e.target.value, {
                    shouldValidate: true,
                  });
                }}
              >
                {updatedActionStageStatus.map((st, index) => (
                  <MenuItem
                    sx={{
                      borderBottom: "1px solid  #E5E0EB",
                    }}
                    key={index}
                    value={st.value}
                  >
                    {st.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>

            {/* باقي الحقول */}
            {itemsData.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} mt={2} key={index}>
                <Box sx={bigFlexBoxStyle}>
                  <Typography sx={lableStyle}>{item.label}</Typography>
                </Box>
                {item.componentType === "datePicker" ? (
                  <YearCustomDatePicker
                    name={item.name}
                    setValue={setValue}
                    sx={{...txtFieldBorderStyle}}
                  />
                ) : (
                  <RHFTextField
                    name={item.name}
                    type="text"
                    placeholder={item.placeholder}
                    sx={{
                      ...txtFieldBorderStyle,
                      width: "100%",
                      marginRight: "12px"
                    }}
                  />
                )}
              </Grid>
            ))}

          </Grid>

          <Grid container mt={3}>
            {/* أرقام الهاتف */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={lableSx}>رقم الهاتف</Typography>
              <Box
                sx={{
                  display: "flex",
                  maxWidth: "100%",
                  flexWrap: "wrap",
                }}
              >
                {phones?.map((phone, index) => (
                  <Grid item xs={12} sm={12} md={6} mb="15px">
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <RHFTextField
                        name={`phone_numbers[${index}].phone_number`}
                        // control={control}
                        placeholder="أدخل رقم الهاتف"
                        type="text"
                        sx={{
                          ...txtFieldBorderStyle,
                          width: "100%",
                          marginRight: "12px",
                        }}
                      />

                      {index > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                          onClick={() => removePhoneHandler(index)}
                        >
                          -
                        </Box>
                      )}

                      {index === 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "center",
                          }}
                        >
                          <img
                            onClick={addNewPhoneHandler}
                            src="/assets/icons/plus.svg"
                            alt=""
                            style={{
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Box>
            </Grid>

            {/* العنوان */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography sx={lableSx}> العنوان</Typography>
              <RHFTextField
                name="address"
                // control={control}
                placeholder="أدخل العنوان"
                id="filled-multiline-static"
                multiline
                rows={3}
                type={"text"}
                sx={{
                  ...multiRowBorderStyle,
                  "& .MuiOutlinedInput-input": {
                    width: "100%",
                  },
                }}
              />
            </Grid>

            {/* الكفيل */}
            <Grid container paddingTop={"10px"}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography mt={1}>
                  الكفيل
                  {OppDetails?.opponent_dependencies?.id ? (
                    <IconButton size={"small"} onClick={() => setOpenEditModal(true)}>
                      <Edit/>
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setOpenModal(true)}>
                      <AddBox/>
                    </IconButton>
                  )}
                </Typography>

                {OppDetails?.opponent_dependencies?.id && (
                  <Box border={"2px solid"} borderColor={"#91aebc"} p={3} bgcolor={"#fff"}>
                    <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"}
                         gap={"0.5em"} sx={{cursor: "pointer",}}
                    >
                      {depDetails.map((detail, index) => (
                        <Box key={index} onClick={() => setOpenEditModal(true)}>
                          <Box gap={"0.5em"} display={"flex"} alignItems={"center"}>
                            <Typography sx={{...lableSx, color: "#6a8490",}}>
                              {detail.label}
                            </Typography>
                            <Typography>{detail.value}</Typography>
                          </Box>
                          {OppDetails?.opponent_dependencies?.attachments?.map(
                            (attachment, index) => (<></>)
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* action buttons */}
          <Grid container columnSpacing={6} pt={2} alignItems={"center"} display={"flex"} justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={6} textAlign="center">
              <LoadingButton
                fullWidth
                size="large"
                variant="Contained"
                sx={loadingButtonStyle}
                loading={isLoadingExport}
                onClick={handleExportAssignment}
              >
                إصدار تنبيه وتكليف
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={12} md={6} textAlign="center">
              <LoadingButton
                onClick={handleSubmit(onSubmit)}
                size="large"
                type="submit"
                variant="Contained"
                sx={MainSaveButtonStyle}
                loading={isLoadingOppUpdate}
              >
                حفظ
              </LoadingButton>
            </Grid>
          </Grid>

        </AccordionDetails>
      </CustomizedAccordion>

      {openModal && (
        <AddVoucherModal open={openModal} onCloseModel={handleClose}/>
      )}

      {openEditModal && (
        <EditVoucherModal
          open={openEditModal}
          detailes={OppDetails?.opponent_dependencies}
          onCloseModel={handleCloseEdit}
        />
      )}
    </>
  );
}
