import {createSlice} from "@reduxjs/toolkit";
import {
  createEmployeeService,
  getEmployeeService,
  getGroupService,
  getUsersNonEmployeeService,
  createGroupService,
  removeUserFromGroupService,
  getEmployeeSideBarService,
  requestVacationService,
  updateEmployeeService,
  getAllVacations,
  getPendingVacations,
  vacationReq,
  getEmployeeServiceList,
  assignUserToGroupService,
  getArchivedUsers,
  restoreUser,
} from "../services/users_service";
import {usersColumnsList} from "../../utils/columnsLists";
import {toast} from "react-toastify";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingCreateUser: false,
  isLoadingUpdateUser: false,
  isLoading: false,
  isLoadingCreate: false,
  employees: [],
  employeesList: [],
  count: null,
  groups: [],
  usersWithoutGroup: [],
  isLoadingRemove: false,
  isLoadingAssign: false,
  employeesSideBar: {},
  isLoadingEmployeesSideBar: false,
  usersColumnsList: usersColumnsList,
  isLoadingAllVacations: false,
  isLoadingPendingVacations: false,
  pendingVacations: [],
  isLoadingvacationReq: false,
  client_type: null,
  balance_status: null,
  matter_status: null,
  id_card_type: null,
  userId: null,
  groupId: null,
  permissions: [],
  isLoadingUsersNonoEmployees: false,
  usersNonemployees: [],
  unersNonEmpCount: null,
  usersNonEmpLastPage: null,
  usersNonEmpNext: null,

  archivedUsers: [],
  isArchivedUsersLoaded: false,
  archivedUsersCount: 0,
  errorOnFetchingArchivedUsers: null
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersColumnsList: (state, action) => {
      state.usersColumnsList = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoadingAllVacations = action.payload;
    },
    setClientType: (state, action) => {
      state.client_type = action.payload;
    },
    setBalanceStatus: (state, action) => {
      state.balance_status = action.payload;
    },
    setMatterStatus: (state, action) => {
      state.matter_status = action.payload;
    },
    setIdCardType: (state, action) => {
      state.id_card_type = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setGroupId: (state, action) => {
      state.groupId = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    setArchivedUsersLoaded: (state, action) => {
      state.isArchivedUsersLoaded = action.payload;
    },
  },
  extraReducers: {
    // ==> CREATE USER
    [createEmployeeService.fulfilled]: (state, action) => {
      state.isLoadingCreateUser = false;
    },
    [createEmployeeService.rejected]: (state, action) => {
      state.isLoadingCreateUser = false;
    },
    [createEmployeeService.pending]: (state) => {
      state.isLoadingCreateUser = true;
    },
    // ==> UPDATE USER
    [updateEmployeeService.fulfilled]: (state, action) => {
      state.isLoadingUpdateUser = false;
      // toast.success("تم التعديل بنجاح");
    },
    [updateEmployeeService.rejected]: (state, action) => {
      state.isLoadingUpdateUser = false;
      toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء تعديل موظف");
    },
    [updateEmployeeService.pending]: (state) => {
      state.isLoadingUpdateUser = true;
    },

    // ==> GET USER
    [getEmployeeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employees = action.payload.data.data;
    },
    [getEmployeeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getEmployeeService.pending]: (state) => {
      state.isLoading = true;
    },
    // ==> GET USER LIST
    [getEmployeeServiceList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employeesList = action.payload.data.results;
      state.count = action.payload.data.count;
    },
    [getEmployeeServiceList.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getEmployeeServiceList.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET GROUP
    [getGroupService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groups = action.payload.data.data;
    },
    [getGroupService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getGroupService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET USERS NON EMPLOYEE
    [getUsersNonEmployeeService.fulfilled]: (state, action) => {
      state.usersWithoutGroup = action.payload.data.data;
      state.isLoadingUsersNonoEmployees = false;
      state.unersNonEmpCount = action.payload.data.count;
      state.usersNonEmpLastPage = action.payload.data.last_page;
      state.usersNonEmpNext = action.payload.data.next;
    },
    [getUsersNonEmployeeService.rejected]: (state, action) => {
      state.isLoadingUsersNonoEmployees = false;
    },
    [getUsersNonEmployeeService.pending]: (state) => {
      state.isLoadingUsersNonoEmployees = true;
    },

    // ==> POST GROUp
    [createGroupService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createGroupService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
      toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء إضافة مجموعة");
    },
    [createGroupService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> REMOVE USER FROM GROUp
    [removeUserFromGroupService.fulfilled]: (state, action) => {
      state.isLoadingRemove = false;
    },
    [removeUserFromGroupService.rejected]: (state, action) => {
      state.isLoadingRemove = false;
    },
    [removeUserFromGroupService.pending]: (state) => {
      state.isLoadingRemove = true;
    },
    // POST =>  ASSIGN EMP TO GROUP

    [assignUserToGroupService.fulfilled]: (state, action) => {
      state.isLoadingAssign = false;
    },
    [assignUserToGroupService.rejected]: (state, action) => {
      state.isLoadingAssign = false;
    },
    [assignUserToGroupService.pending]: (state) => {
      state.isLoadingAssign = true;
    },

    // ==> GET SidBar
    [getEmployeeSideBarService.fulfilled]: (state, action) => {
      state.isLoadingEmployeesSideBar = false;
      state.employeesSideBar = action.payload.data;
    },
    [getEmployeeSideBarService.rejected]: (state, action) => {
      state.isLoadingEmployeesSideBar = false;
    },
    [getEmployeeSideBarService.pending]: (state) => {
      state.isLoadingEmployeesSideBar = true;
    },

    // ==> Request Vecation
    [requestVacationService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [requestVacationService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [requestVacationService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // GET =>  GET ALL VACATIONS

    [getAllVacations.fulfilled]: (state, action) => {
      state.isLoadingAllVacations = false;
      state.allVacations = action.payload;
    },
    [getAllVacations.rejected]: (state, action) => {
      state.isLoadingAllVacations = false;
    },
    [getAllVacations.pending]: (state) => {
      state.isLoadingAllVacations = true;
    },
    // GET =>  GET Pending VACATIONS

    [getPendingVacations.fulfilled]: (state, action) => {
      state.isLoadingPendingVacations = false;
      state.pendingVacations = action.payload;
    },
    [getPendingVacations.rejected]: (state, action) => {
      state.isLoadingPendingVacations = false;
    },
    [getPendingVacations.pending]: (state) => {
      state.isLoadingPendingVacations = true;
    },
    [vacationReq.fulfilled]: (state, action) => {
      state.isLoadingvacationReq = false;
    },
    [vacationReq.rejected]: (state, action) => {
      state.isLoadingvacationReq = false;
    },
    [vacationReq.pending]: (state) => {
      state.isLoadingvacationReq = true;
    },


    [getArchivedUsers.pending]: (state, action) => {
      state.isArchivedUsersLoaded = false;
    },
    [getArchivedUsers.fulfilled]: (state, action) => {
      state.archivedUsers = action.payload.data?.data;
      state.archivedUsersCount = action.payload.data?.last_page;
      state.isArchivedUsersLoaded = true;
    },
    [getArchivedUsers.rejected]: (state, action) => {
      state.isArchivedUsersLoaded = true;
      state.errorOnFetchingArchivedUsers = action?.payload.data?.message ?? action?.payload.data?.data?.detail;
    },

    [restoreUser.pending]: (state, action) => {
      state.isArchivedUsersLoaded = true;
    },
    [restoreUser.fulfilled]: (state, action) => {
      state.isArchivedUsersLoaded = false;
    },
    [restoreUser.rejected]: (state, action) => {
      state.isArchivedUsersLoaded = true;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setUsersColumnsList,
  setIsLoading,
  setClientType,
  setBalanceStatus,
  setMatterStatus,
  setIdCardType,
  setUserId,
  setGroupId,
  setUserPermissions,
  setArchivedUsersLoaded,
} = slice.actions;
