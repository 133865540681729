import {useMemo} from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import DataTableHeadCell from './DataTableHeadCell';
import DataTableBodyCell from './DataTableBodyCell';

function DataTable({table, simpleStyle, onClickRow}) {

  const columns = useMemo(() => table.columns, [table]);
  const rows = useMemo(() => table.rows, [table]);

  function handleClickRow(event, row) {
    if (onClickRow)
      onClickRow(row);
  }

  return (
    <TableContainer sx={{boxShadow: 'none'}}>
      <Table>
        <Box component='thead'>
          <TableRow>
            {columns?.map((column, key) => (
              <DataTableHeadCell
                key={key}
                width={column.width ? column.width : 'auto'}
              >
                {column.title}
              </DataTableHeadCell>
            ))}
          </TableRow>
        </Box>
        <TableBody>
          {rows?.map((row, rowIndex) => (
            <TableRow
              onClick={(event) => handleClickRow(event, row)}
              key={rowIndex}
              sx={{
                cursor: onClickRow ? "pointer" : "default",
                backgroundColor: simpleStyle ? "inherit" : (rowIndex % 2 === 0 ? '#ccdde4' : '#edeff1')
              }}
            >
              {columns.map((column, colIndex) => {
                const value = column.cell ? column.cell({row}) : row?.[column.accessor];
                return (
                  <DataTableBodyCell key={colIndex}>
                    {value}
                  </DataTableBodyCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

DataTable.propTypes = {
  table: PropTypes.object.isRequired,
};

export default DataTable;
