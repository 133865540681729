import {createAsyncThunk} from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// POST =>  POST  debt_collection_service
export const uploadDeptCollection = createAsyncThunk(
  "debtCollection/uploadDeptCollection",
  async ({data, id}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/clients/${id}${URLS.uploadDeptCollection}/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>  POST  debt_collection_service
// export const searchUploadDeptCollection = createAsyncThunk(
//   "debtCollection/uploadDeptCollection",
//   async ({ data, id, search_value }, thunkAPI) => {
//     try {
//       const response = await axios.post(
//         `${URLS.debt_collection}/clients/${id}${URLS.uploadDeptCollection}/&search_value=${search_value}`,
//         data,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//       return response.data;
//     } catch (e) {
//       return thunkAPI.rejectWithValue(e);
//     }
//   }
// );

// Get =>  Get  get details of dept collection
export const getDetails = createAsyncThunk(
  "debtCollection/getDetails",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/clients/${id}${URLS.getDetails}`
      );

      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Get =>  Get  get details of dept collection
export const getOpponentsForCollection = createAsyncThunk(
  "debtCollection/getOpponentsForCollection",
  async (data, thunkAPI) => {
    try {
      const personalStatus = data?.personal_status ?? false;
      const size = data?.page_size ?? 25;
      const query = data?.search_value ?? "";
      const response = await axios.get(
        `${URLS.debt_collection}/clients/${data?.id}/opponents/?page=${data?.page}&page_size=${size}` +
        `&personal_status=${personalStatus}&search_value=${query}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Get =>  Get  get details of dept collection
export const getSearchOpponents = createAsyncThunk(
  "debtCollection/getSearchOpponents",
  async ({data, page}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/opponents/search/?page=${page}`,
        data
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  Update  debt_collection
export const updateDeptCollection = createAsyncThunk(
  "debtCollection/updateDeptCollection",
  async ({id, dataToSent}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/clients/${id}${URLS.updateDeptCollection}`,
        dataToSent,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// Delete =>  Delete  attachment
export const deleteAttachment = createAsyncThunk(
  "debtCollection/deleteAttachment",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.debt_collection}/attachments/${id}${URLS.deleteAttachment}`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// Delete =>  Delete  EXPENSE
export const deleteExpense = createAsyncThunk(
  "debtCollection/deleteExpense",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.debt_collection}/expenses/${id}/delete`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Get =>  Get  Get Client Contract
export const getClientContract = createAsyncThunk(
  "debtCollection/getClientContract",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/clients/${id}${URLS.showContract}`
      );
      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>  POST  Update Client Contract
export const updateClientContract = createAsyncThunk(
  "debtCollection/updateClientContract",
  async ({id, dataToSent}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/clients/${id}${URLS.updateContract}`,
        dataToSent
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Get =>  Get  Get Client Collection Service
export const getClientsCollectionService = createAsyncThunk(
  "debtCollection/getClientsCollectionService",
  async (data, thunkAPI) => {
    try {
      const personalStatus = data?.personal_status ?? false;
      const size = data?.page_size ?? 10;
      const response = await axios.get(
        `${URLS.debt_collection}/clients?page=${data?.page}&personal_status=${personalStatus}&page_size=${size}`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// Get =>  Get  Opponents Search
export const searchOpponents = createAsyncThunk(
  "debtCollection/searchOpponents",
  async (data, thunkAPI) => {
    try {
      const personalStatus = data?.personal_status ?? false;
      const query = data?.search_value ?? "";
      const response = await axios.get(
        `${URLS.debt_collection}/clients/${data?.id}/opponents/search/?page=${data?.page}` +
        `&search_value=${query}&personal_status=${personalStatus}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**export const getProceduressDetails = createAsyncThunk(
 "debtCollection/getProceduressDetails",
 async (data, thunkAPI) => {
    console.log("data", data);
    try {
      const personalStatus = data.personalMode ?? false;
      let url =
        `${URLS.debt_collection}/opponents/${data?.id}/procedures/?page=${data?.page}` +
        `&personal_status=${personalStatus}`;

      // Append dateToFilter properties to the URL
      if (data.dateToFilte) {
        Object.keys(data.dateToFilter).forEach((key) => {
          url += `&${key}=${encodeURIComponent(data.dateToFilter[key])}`;
        });
      }
      const type = data?.type;
      if (type) {
        url += `&type=${type}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
 ); */

export const getProceduressDetails = createAsyncThunk(
  "debtCollection/getProceduressDetails",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS?.debt_collection}/opponents/${params?.id}/procedures/`,
        {
          params: {
            ...params?.data,
          },
        }
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE  Procedure
export const createProcedure = createAsyncThunk(
  "deptCollection/createProcedure",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/opponents/procedures/add/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Get =>  Get  get details of  Procedures
export const getOpponentsDetailsService = createAsyncThunk(
  "debtCollection/getOpponentsDetailsService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/opponents/${id}/show/`
      );

      return response?.data?.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  PUT  OPP
export const updateOpponentService = createAsyncThunk(
  "debtCollection/updateOpponentService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.debt_collection}/clients/${params?.id}/upload-opponents/`,
        params?.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// INVOICES

// Get =>  Get  get details of  invoices
export const getInvoices = createAsyncThunk(
  "debtCollection/getInvoices",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/clients/${id}/invoices/`
      );

      return response?.data?.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET INVOICE SHOW
export const getInvoiceShow = createAsyncThunk(
  "matter/getInvoiceShow",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/invoices/${id}/show`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST => CREATE INVOICE
export const createInvoice = createAsyncThunk(
  "matter/createInvoice",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/invoices/create/`,
        data
      );
      return response.data; // Ensure only the response data is returned
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//POST => Create Dept Client Service
export const createDeptClientService = createAsyncThunk(
  "general/createDeptClient",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createDeptClientURL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE => DELETE PROCEDURE

export const deleteProcedures = createAsyncThunk(
  "debtCollection/deleteProcedures",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.debt_collection}/opponents/procedures/${id}/delete/`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// UPDATE => UPDATE PROCEDURE

export const updateProcedures = createAsyncThunk(
  "debtCollection/updateProcedures",
  async ({id, data}, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.debt_collection}/opponents/procedures/${id}/update/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getOpponentsDetails = createAsyncThunk(
  "debtCollection/getOpponentsDetails",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/opponents/${id}/show/`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// PUT =>  PUT  OPP
export const updateOpponentDetails = createAsyncThunk(
  "debtCollection/updateOpponentDetails",
  async ({id, data}, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.debt_collection}/opponents/${id}/update/`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getTasks = createAsyncThunk(
  "debtCollection/getTasks",
  async ({date, late, client_id, personal_situation, page}, thunkAPI) => {
    try {
      const params = new URLSearchParams();

      if (date !== undefined && date !== null) {
        params.append("date", date);
      }
      if (late !== undefined && late !== null) {
        params.append("late", late);
      }
      if (client_id !== undefined && client_id !== null) {
        params.append("client_id", client_id);
      }
      if (personal_situation !== undefined && personal_situation !== null) {
        params.append("personal_situation", personal_situation);
      }
      if (page !== undefined && page !== null) {
        params.append("page", page);
      }

      const response = await axios.get(
        `${
          URLS.debt_collection
        }/opponents/procedures/tasks?${params.toString()}`
      );

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getClientsTemplates = createAsyncThunk(
  "debtCollection/getClientsTemplates",
  async ({id, for_update, type, page}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/clients/${id}/templates/?for_update=${for_update}&type=${type}&page=${page}`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET OPP IN FILE
export const getOppInFile = createAsyncThunk(
  "debtCollection/getOppInFile",
  async (data, thunkAPI) => {
    try {
      const searchValue = data?.searchValue ?? "";
      const personalStatus = data?.personalMode ?? false;
      const size = data?.page_size ?? false;

      let url =
        `${URLS.debt_collection}/template-files/${data?.id}/opponents?page=${data?.page}&page_size=${size}` +
        `&search_value=${searchValue}&personal_status=${personalStatus}`;

      if (data?.queryParams) {
        url += `&${data?.queryParams}`;
      }

      return await axios.get(url);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET - Uploaded Payment File Data
export const getPaymentFileData = createAsyncThunk(
  "debtCollection/getPaymentFileData",
  async (data, thunkAPI) => {
    try {
      return await axios.get(
        `${URLS.debt_collection}/opponents/payments/get-by-file/${data?.id}?page=${data?.page}`
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => GET FILE STATUS
export const getFileStatusService = createAsyncThunk(
  "debtCollection/getFileStatus",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/opponents/file-status`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET => GET ACTION STAGE
export const getActionStageService = createAsyncThunk(
  "debtCollection/getActionStageService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/opponents/action-stage`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     EXPORT PAYMENT ISSUE
export const exportAssignmentService = createAsyncThunk(
  "debtCollection/exportAssignmentService",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/opponents/${id}/export_doxc_issuing_payment_notice/`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// DELETE => DELETE TEMP

export const deleteTmplate = createAsyncThunk(
  "debtCollection/deleteTEMP",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${URLS.debt_collection}/templates/${id}/delete/`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST => CREATE TEMP
export const createTmplate = createAsyncThunk(
  "debtCollection/createTmplate",
  async (formData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/templates/create/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST => POST FILE FIELDS
export const getTmplateFields = createAsyncThunk(
  "debtCollection/getTmplateFields",
  async ({formData}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/templates/get-file-fields/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     GET SYSTEM FIELDS
export const getSystemFieldsService = createAsyncThunk(
  "debtCollection/getSystemFieldsService",
  async ({type}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/templates/${URLS.getFieldsChoices}/?type=${type}`
      );
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const opponentFilterService = createAsyncThunk(
  "debtCollection/opponentFilterService",
  async (data, thunkAPI) => {
    try {
      const personalStatus = data?.personalMode ?? false;
      return await axios.get(
        `${URLS.debt_collection}/clients/${data?.id}/opponents/filter/?page=${data?.page}&${data?.queryParams}` +
        `&personal_status=${personalStatus}`
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>     EXPORT PAYMENT ISSUE
export const exportOpponentsExcelService = createAsyncThunk(
  "debtCollection/exportOpponentsExcelService",
  async ({id, queryParams}, thunkAPI) => {
    try {
      const hasQueryParams = queryParams && Object.keys(queryParams).length > 0;
      const url = `${URLS.debt_collection}/clients/${id}/opponents/export${
        hasQueryParams ? `?${queryParams}` : ""
      }`;
      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     GET EXPENS STATMENTS
export const expenseStatementsService = createAsyncThunk(
  "debtCollection/expenseStatementsService",
  async ({status, id, page, searchValue, filterParams, page_size}, thunkAPI) => {
    try {
      let url = `${URLS.debt_collection}/expense-statements/get_all/` +
        `?page=${page}&search_value=${searchValue ?? ""}&page_size=${page_size ?? 10}`;
      if (status) {
        url += `&status=${status}`;
      }
      if (filterParams) {
        url += `&${filterParams ?? ""}`;
      }
      return await axios.get(url);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     GET EXPENSES
export const getExpensesService = createAsyncThunk(
  "debtCollection/getExpensesService",
  async ({page, pageSize}, thunkAPI) => {
    try {
      return await axios.get(`${URLS.debt_collection}/expenses/get_all/?page=${page}&page_size=${pageSize ?? 10}`);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>     CREATE EXPENS STATMENTS
export const createStatementsService = createAsyncThunk(
  "debtCollection/createStatementsService",
  async ({data}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/expense-statements/create/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>     CREATE EXPENS STATMENTS
export const createExpensesOpp = createAsyncThunk(
  "debtCollection/createExpensesOpp",
  async ({data}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/expense-opponents/create/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     CHECK EXPENS IF EXSIST
export const checkExpense = createAsyncThunk(
  "debtCollection/checkExpense",
  async ({data}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/check_if_expenses_exists_for_opponent/?opponent_id=${data.opponent_id}&expense_id=${data.expense_id}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     GET EXPENSESE OPP
export const getExpensesOppService = createAsyncThunk(
  "debtCollection/getExpensesOppService",
  async ({id, page, page_size}, thunkAPI) => {
    try {
      let url = `${URLS.debt_collection}/expense-statements/${id}/expense-opponents/get_all/?page=${page}&page_size=${page_size ?? 10}`;

      return await axios.get(url);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getCustudyExpenseService = createAsyncThunk(
  "debtCollection/getCustudyExpenseService",
  async ({id, movement_date_before, movement_date_after, page}, thunkAPI) => {
    try {
      let url = `${URLS.debt_collection}/custody_expenses_for_employee/${id}/get_all/?page=${page ?? 1}`;
      const queryParams = [];
      if (movement_date_before) {
        queryParams.push(`movement_date_before=${movement_date_before}`);
      }
      if (movement_date_after) {
        queryParams.push(`movement_date_after=${movement_date_after}`);
      }
      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      return await axios.get(url);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>     CREATE EXPENS STATMENTS
export const createExpensesCustudy = createAsyncThunk(
  "debtCollection/createExpensesCustudy",
  async ({data}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/custody_expenses/create/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>     CREATE EXPENS STATMENTS
export const createExpensesType = createAsyncThunk(
  "debtCollection/createExpensesType",
  async ({data}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/expenses/create/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const createDependency = createAsyncThunk(
  "debtCollection/createDependency",
  async ({data}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/opponents/dependencies/create/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     GET procedures types
export const getProceduresTypesService = createAsyncThunk(
  "debtCollection/getProceduresTypesService",
  async (_, thunkAPI) => {
    try {
      let url = `${URLS.debt_collection}/opponents/procedures/types/`;
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     GET PAYMENTS
export const getPaymentsService = createAsyncThunk(
  "debtCollection/getPaymentsService",
  async ({id}, thunkAPI) => {
    try {
      let url = `${URLS.debt_collection}/opponents/${id}/payments/get_all/`;
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET USER
export const getEmployeeService = createAsyncThunk(
  "debtCollection/getEmployee",
  async (data, thunkAPI) => {
    try {
      const query = data?.search_value || "";
      const idsQuery = data?.ids?.map((id, index) => `ids[${index}]=${id}`).join("&") || "";

      return await axios.get(
        `${URLS.getEmployeesURL}?page=${data?.page}&search_value=${query}&${idsQuery}`
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET jobs
export const getJobsService = createAsyncThunk(
  "debtCollection/getJobsService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getJobsURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE USER
export const createEmployeeService = createAsyncThunk(
  "debtCollection/createEmployeeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createEmployeeURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  REmove USER
export const removeUserService = createAsyncThunk(
  "debtCollection/removeUserService",
  async ({id}, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.employees}/${id}`);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const createPayment = createAsyncThunk(
  "debtCollection/createPayment",
  async ({data}, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.debt_collection}/opponents/payments/create/`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET expense opponents
export const getExpenseOpponents = createAsyncThunk(
  "debtCollection/getExpenseOpponents",
  async ({id, page}, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.debt_collection}/opponents/${id}/expenses/?page=${page}`
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET expense opponents
export const uploadManyOpponents = createAsyncThunk(
  "debtCollection/uploadManyOpponents",
  async ({data}, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.debt_collection}/opponents/update-many/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  HAND OVER TO THE RESPONSIBLE
export const handOverRequestService = createAsyncThunk(
  "debtCollection/handOverRequestService",
  async (data, thunkAPI) => {
    try {
      return await axios.put(
        `${URLS.debt_collection}/expense-statements/${data?.id}/hand-over-to-the-responsible/`,
        null
      );
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  RECIVE REQUEST
export const ReciveRequestService = createAsyncThunk(
  "debtCollection/handOverRequestService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.debt_collection}/expense-statements/${id}/receive/`,
        null
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  UPDATE OPP
export const updateOpponentsDependencisService = createAsyncThunk(
  "debtCollection/updateOpponentsDependencisService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.debt_collection}/opponents/dependencies/${params?.id}/update/`,
        params?.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Delete =>  Remove USER
export const deleteUserService = createAsyncThunk(
  "debtCollection/deleteUserService",
  async ({id}, thunkAPI) => {
    try {
      return await axios.delete(`${URLS.employees}/${id}/delete/`);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
