import {Alert, Box, Divider, IconButton, MenuItem, Pagination, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AccountCircle, ArrowForward, MoreVert, RestartAlt,} from "@mui/icons-material";
import DataTable from "../../../components/table/DataTable";
import TableNoData from "../../../components/table/TableNoData";
import {useDispatch, useSelector} from "react-redux";
import {getArchivedUsers, restoreUser} from "../../../redux/services/users_service";
import {setArchivedUsersLoaded} from "../../../redux/slices/users";
import {baseUrl} from "../../../urls";
import MenuPopover from "../../../components/menu-popover";
import {toast} from "react-toastify";

function Archive() {
  const {
    archivedUsers,
    isArchivedUsersLoaded,
    archivedUsersCount,
    errorOnFetchingArchivedUsers
  } = useSelector((state) => state.users);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [table, setTable] = useState({columns: [], rows: []});
  const [tempUser, setTempUser] = useState(null);
  const [page, setPage] = useState(1);

  // State for managing the popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (event, row) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setTempUser(row);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handlePagination = (value) => {
    setPage(value);
    setArchivedUsersLoaded(false);
  };

  function handleRestoreUser(event) {
    handleClosePopover();
    if (tempUser)
      dispatch(restoreUser({id: tempUser.id})).then((res) => {
        if (restoreUser.fulfilled.match(res)) {
          toast.success("تمت استعادة الموظف بنجاح")
          setTempUser(null);
        } else
          toast.error("هناك خطأ ما");
      });
  }

  useEffect(() => {
    if (!isArchivedUsersLoaded) {
      dispatch(getArchivedUsers({page}));
    }
  }, [isArchivedUsersLoaded, dispatch]);


  useEffect(() => {
    if (archivedUsers && archivedUsers?.length > 0) {
      setTable({
        columns: [
          {title: "الرقم التسلسلي", accessor: "id"},
          {
            title: "الاسم", accessor: "full_name",
            cell: ({row}) => {
              return row?.first_name + " " + row?.last_name;
            },
          },
          {
            title: "أرقام الهاتف", accessor: "phone_numbers",
            cell: ({row}) => {
              return (
                <div>
                  {row?.phone_numbers?.map((item, index) => (
                    <div key={index}>{item?.phone_number}</div>
                  ))}
                </div>
              );
            },
          },
          {title: "البريد الالكتروني", accessor: "email"},
          {
            title: "الصورة الشخصية", accessor: "profile_picture",
            cell: ({row}) => {
              if (row?.profile_picture) {
                return (
                  <img
                    src={baseUrl + row?.profile_picture}
                    alt="profile picture"
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                );
              } else {
                return (
                  <AccountCircle color={"primary"} style={{width: "40px", height: "40px"}}/>
                );
              }
            },
          },
          {
            title: "الاجراءات", accessor: "actions",
            cell: ({row}) => {
              return (
                <IconButton onClick={(event) => handleOpenPopover(event, row)}>
                  <MoreVert/>
                </IconButton>
              );
            },
          },
        ],
        rows: archivedUsers,
      });
    }
  }, [archivedUsers]);

  return (
    <>
      {errorOnFetchingArchivedUsers && (
        <Alert severity="error">{errorOnFetchingArchivedUsers}</Alert>
      )}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={{xs: "column", sm: "row"}}
      >
        <Box display={"flex"} alignItems={"center"} my={2}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowForward/>
          </IconButton>
          <Typography fontWeight={"bold"} color={"#6A8490"} fontSize={"24px"} lineHeight={"28px"}>
            أرشيف الموظفين
          </Typography>
        </Box>
      </Box>

      <Divider/>

      <Box>
        {archivedUsers?.length > 0 ? (
          <Box mt={2} bgcolor={"white"}>
            <DataTable table={table}/>
            <Box p={3} display={"flex"} justifyContent={"end"}>
              <Pagination
                page={page}
                color="primary"
                count={archivedUsersCount}
                onChange={(event, value) => handlePagination(value)}
              />
            </Box>
          </Box>
        ) : (
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <TableNoData isNotFound/>
          </Box>
        )}
      </Box>

      {/* Popover */}
      <MenuPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        disabledArrow
      >
        <MenuItem onClick={((event) => handleRestoreUser(event))}>
          <Box p={1} display={"flex"}>
            <RestartAlt color={"primary"}/>
            <Typography>
              استعادة
            </Typography>
          </Box>
        </MenuItem>
      </MenuPopover>
    </>
  );
}

export default Archive;
