import {Box, TextField} from "@mui/material";
import {txtFieldBorderStyle} from "../../styles/OpponentListStyles";
import {TablePaginationCustom} from "./index";
import {toast, ToastContainer} from "react-toastify";
import React from "react";
import Typography from "@mui/material/Typography";


function EnhancedTablePagination(
  {
    page,
    setPage,
    lastPage,
    count,
    currentPage,
    handleCurrentPageChange,
    rowsPerPage,
    handlePagination,
    onChangeRowsPerPage,
    dense,
    onChangeDense,
    isSize,
    pageSize,
    handlePageSizeChange,
  }) {
  const [tempPageSize, setTempPageSize] = React.useState(pageSize ?? 25);

  const handleTempPageSizeChange = (event) => {
    const value = event.target.value;
    setTempPageSize(value);
    let newPageSize = Number(value);

    if (newPageSize > 0 && newPageSize < 100)
      handlePageSizeChange(newPageSize);
    else
      toast.warn("عدد النتائج في الصفحة يجب ان يكون بين 1 - 100");
  };

  const handlePageInputChange = (event) => {
    const value = event.target.value;

    const pageNumber = Number(value);

    handleCurrentPageChange(event, pageNumber);
    // console.log(pageNumber);

    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= lastPage) {
      setPage(pageNumber);
      handlePagination(null, pageNumber);

    } else if (value === "" || value === 0) {
      setPage(1);
      handlePagination(null, 1);

    } else if (value > lastPage) {
      toast.warn("لقد تجاوزت عدد الصفحات");
    } else {
      toast.error("الرجاء ادخال رقم صفحة صحيح");
    }

  };


  return (count
      ? <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>

        <Box display={"flex"} alignItems={"center"} p={2}>
          <TextField
            type="number"
            value={tempPageSize}
            onChange={handleTempPageSizeChange}
            sx={{width: "4em"}}
            size={"small"}
            inputProps={{
              style: {textAlign: "center"},
            }}
          />
          <Typography px={1}>نتيجة في الصفحة</Typography>
        </Box>

        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
          <TextField
            type="number"
            value={currentPage}
            sx={txtFieldBorderStyle}
            onChange={handlePageInputChange}
            inputProps={{
              style: {textAlign: "center"},
            }}
          />

          <Box mx={2}>
            <img alt="go to page" src={"/assets/icons/Line 2.svg"}/>
          </Box>

          <TablePaginationCustom
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChange={handlePagination}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
            isSize={isSize}
            pageSize={pageSize ?? 10}
          />
          <ToastContainer/>
        </Box>

      </Box>
      : <></>
  );
}

export default EnhancedTablePagination;