import {createSlice} from "@reduxjs/toolkit";

import {getStatisticService, getStatisticDataService} from "../services/statistics_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingStatistic: false,
  Statistic: [],
  statistics: null,
  error: null,
  statisticLoaded: false,
};

const slice = createSlice({
  name: "statistic",
  initialState,
  reducers: {},
  extraReducers: {
    // --- GET STATISTIC ---
    [getStatisticService.fulfilled]: (state, action) => {
      state.isLoadingStatistic = false;
      state.Statistic = action.payload.data;
    },
    [getStatisticService.rejected]: (state, action) => {
      state.isLoadingStatistic = false;
    },
    [getStatisticService.pending]: (state) => {
      state.isLoadingStatistic = true;
    },

    // --- Get New Statistics ---
    [getStatisticDataService.fulfilled]: (state, action) => {
      state.statisticLoaded = true;
      state.statistics = action.payload?.data?.data;
    },
    [getStatisticDataService.rejected]: (state, action) => {
      state.statisticLoaded = true;
      state.error = action.payload?.error;
    },
    [getStatisticDataService.pending]: (state) => {
      state.statisticLoaded = false;
    },

    //--- ---

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {setUsersColumnsList, setIsLoading} = slice.actions;
