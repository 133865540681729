import React from "react";

import {
  Alert,
  Box,
  Backdrop,
  Button,
  Modal,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";

import FormProvider, {RHFSelect, RHFTextField} from "../../../../components/hook-form";

import {useForm} from "react-hook-form";

import {yupResolver} from "@hookform/resolvers/yup";

import * as Yup from "yup";

import {LoadingButton} from "@mui/lab";

import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";

import {useDispatch, useSelector} from "react-redux";

import dayjs from "dayjs";

import {toast} from "react-toastify";

import {
  createPayment,
  getOpponentsDetails,
  getPaymentsService,
} from "../../../../redux/services/debt_collection_service";

// ---Styles ---
import {
  ModalSx,
  txtFieldBorderStyle,
  loadingButtonStyle,
  modalTitleSx,
  buttonStyle,
  boxOfActionButtonStyle,
  labelSx,
  transparentBorderTxtField,
  boxOfModalStyle
} from "../../../../styles/AddNewPaymentModalStyles"

//_______________________________________________________________

export default function AddNewPaymentModal({open, onCloseModel}) {

  const AddNewPaymentSchema = Yup.object().shape({});
  const dispatch = useDispatch();
  const defaultValues = {
    payment_date: dayjs(),
  };

  const {isLoadingCrePay, opponentId} = useSelector(
    (state) => state.deptCollection
  );

  const methods = useForm({
    resolver: yupResolver(AddNewPaymentSchema),
    defaultValues,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: {errors},
  } = methods;

  const onSubmit = async (data) => {
    console.log("data", data);

    let dataToSend = {
      ...data,
      opponent: opponentId,
      payment_date: data.payment_date.format("YYYY-MM-DD"),
    };
    dispatch(createPayment({data: dataToSend})).then((response) => {
      if (response?.payload?.status === 201) {
        onCloseModel();
        toast.success("تمت عملية الإضافة بنجاح")
        dispatch(getPaymentsService({id: opponentId, page: 1}));
        dispatch(getOpponentsDetails({id: opponentId}));
      } else {
        response?.payload?.errors?.map((error, index) => (
          toast.warn(error)
        ))
      }
    });
  };

  // ----------- JSX Code -------------
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{backdrop: Backdrop}}
        slotProps={{backdrop: {timeout: 500,},}}
      >
        <Box sx={{...ModalSx}}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src={"/assets/icons/x.svg"} alt="" style={{}}/>
          </Box>

          <Typography sx={modalTitleSx}>إضافة سداد</Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <Grid container columnSpacing={3} mt="30px">
              <Grid item xs={6}>
                <Typography sx={labelSx}>قيمة السداد</Typography>

                <RHFTextField
                  name="value"
                  control={control}
                  placeholder={"أدخل قيمة المصروف"}
                  InputProps={{
                    endAdornment: (
                      <RHFSelect
                        name="num"
                        sx={{
                          ...transparentBorderTxtField,
                          //width: "80px",
                        }}
                        SelectProps={{
                          native: false,
                          IconComponent: () => (
                            <img src={"/assets/icons/Icon.svg"} alt=""/>
                          ),
                        }}
                        onChange={(e) => {
                          setValue("num", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                      >
                        <MenuItem value="KY">KD</MenuItem>
                        <MenuItem value="KY">AED</MenuItem>
                        <MenuItem value="SY">SY</MenuItem>
                      </RHFSelect>
                    ),
                  }}
                  sx={{...txtFieldBorderStyle}}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography sx={labelSx}>نوع السداد</Typography>
                <RHFSelect
                  name="type"
                  sx={{
                    ...txtFieldBorderStyle,
                    "& .MuiSelect-select span::before": {
                      content: "' اختر نوع السداد'",
                      color: "#697D95",
                      fontWeight: 300,
                      fontSize: "14px",
                    },
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => (
                      <img src={"/assets/icons/Icon.svg"} alt=""/>
                    ),
                  }}
                  onChange={(e) => {
                    setValue("type", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                >
                  <MenuItem value="دفعة">دفعة</MenuItem>
                  <MenuItem value="تسوية">تسوية</MenuItem>
                </RHFSelect>
              </Grid>
            </Grid>

            <Grid container columnSpacing={3} mt="30px">
              <Grid item xs={6}>
                <Typography sx={labelSx}>طريقة السداد</Typography>
                <RHFSelect
                  name="method"
                  sx={{
                    ...txtFieldBorderStyle,
                    "& .MuiSelect-select span::before": {
                      content: "' اختر نوع السداد'",
                      color: "#697D95",
                      fontWeight: 300,
                      fontSize: "14px",
                    },
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => (
                      <img src={"/assets/icons/Icon.svg"} alt=""/>
                    ),
                  }}
                  onChange={(e) => {
                    setValue("method", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                >
                  <MenuItem value="فرع">فرع</MenuItem>
                  <MenuItem value="كي نت">كي نت</MenuItem>
                  <MenuItem value="رابط">رابط</MenuItem>
                  <MenuItem value="كاش">كاش</MenuItem>
                </RHFSelect>
              </Grid>

              <Grid item xs={6}>
                <Typography sx={labelSx}>تاريخ السداد</Typography>

                <YearCustomDatePicker
                  name="payment_date"
                  inputFormat="YYYY-MM-DD"
                  control={control}
                  sx={{...txtFieldBorderStyle}}
                  setValue={setValue}
                />
              </Grid>
            </Grid>

            {/** save Button */}
            <Box sx={{display: "flex", justifyContent: "center"}}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={{...loadingButtonStyle, width: "400px", mt: "30px"}}
                loading={isLoadingCrePay}
              >
                حفظ وإضافة جديد{" "}
              </LoadingButton>
            </Box>

            {/** actions Button */}
            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                loading={isLoadingCrePay}
                sx={loadingButtonStyle}
              >
                حفظ
              </LoadingButton>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="button"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
