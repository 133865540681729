// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  Sun: "الأحد",
  Mon: "الإثنين",
  Tue: "الثلاثاء",
  Wed: "الأربعاء",
  Thu: "الخميس",
  Fri: "الجمعة",
  Sat: "السبت",
  full_name: "الاسم الكامل",
  id: "المسلسل",
  phone_numbers: "أرقام الهاتف",
  contract_number: "رقم العقد",
  file_status: "حالة الملف",
  action_stage: "مرحلة الإجراء",
  claim_value: "قيمة المطالبة",
  amount_paid: "المبلغ المدفوع",
  entry_date: "تاريخ الإدخال",
  execution_court: "محكمة التنفيذ",
  reason_for_execution: "سبب التنفيذ",
  performance_fee_value: "قيمة رسوم الأداء",
  performance_number_value: "قيمة عدد الأداء",
  total_claim: "إجمالي المطالبة",
  total_remainder: "إجمالي المتبقي",
  automatic_number: "الرقم التلقائي",
  address: "العنوان",
  city: "المدينة",
  province: "المحافظة",
  email: "البريد الإلكتروني",
  nationality: "الجنسية",
  civil_number: "رقم المدني",
  separated_number: "الرقم المفصول",
  appointment_date: "تاريخ الموعد",
  date_of_court_filing: "تاريخ تقديم المحكمة",
  assigned_employee: "الموظف المكلف",
  client: "العميل",
  phone_number4: "رقم الهاتف1",
  phone_number3: "رقم الهات2",
  phone_number2: "رقم الهاتف3",
  phone_number1: "رقم الهاتف4",
  debtor: `مدين`,
  creditor: `دائن`,
  tangible: `مادي`,
  intangible: "غير مادي",
  unpaid: "غير مدفوع",
  paid: "مدفوع",
  cash: "كاش",
  partially_paid: "مدفوع جزئياً",
  approved: "موافق عليها",
  rejected: "مرفوضة",
  pending: " بانتظار الموافقة",
  COMPANY: "شركة",
  INDIVIDUAL: "أفراد",
  under_review: "لم يتم تسليمه بعد",
  delivered: "مُسلّم",
  not_delivered: "تحت المراجعة",
  file_number: "رقم الملف",
  expenses: "المصروفات",
  governorate: "المحافظة",
  duplicate_files: "الملفات المكررة",
  employer: "جهة العمل",
  rejected_automatic_number_1: "الرقم الآلي المرفوض 1",
  rejected_automatic_number_2: "الرقم الآلي المرفوض 2",
  date_of_delivery_to_court: "تاريخ التسليم للمحكمة",
  date_of_mailing: "تاريخ البعثة البريدية",
  date_of_delivery_for_execution: "تاريخ التسليم للتنفيذ",
  date_of_travel_ban_order: "تاريخ أمر منع السفر",
  date_of_promissory_note: "تاريخ السند الإذني",
  value_of_promissory_note: "قيمة السند الإذني",
  date_of_advertisement: "تاريخ الإعلان",
  date_of_judgment: "تاريخ الحكم",
  date_of_demarcation: "تاريخ الترسيم",

};

export default en;
