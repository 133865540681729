import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_APP_BASE_URL,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("Token")}`,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (401 === error.response.status) {
      localStorage.removeItem("Token");
      window.location.href = "/auth/login";
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
