import React, {useState} from "react";

import {Box, Modal, Typography, Backdrop, Alert, Grid, Button, Checkbox} from "@mui/material";

import FormProvider, {RHFTextField} from "../../../../components/hook-form";

import {useForm} from "react-hook-form";

import {LoadingButton} from "@mui/lab";

import {useDispatch, useSelector} from "react-redux";

// styles
import {
  multiRowBorderStyle,
  checkBoxConatainerStyle,
  ModalSx,
  lableStyle,
  txtFieldBorderStyle,
  checkBoxSx,
  loadingButtonStyle,
  modalTitleSx,
  lableCheckBoxStyle,
  buttonStyle,
  boxOfActionButtonStyle,
  lableSx,
  boxOfModalStyle,
  saveButtonStyle
} from "../../../../styles/MainInformationFormStyles";

//--
import {createDependency, getOpponentsDetails} from "../../../../redux/services/debt_collection_service";

//_______________________________________________________________

export default function AddVoucherModal({open, onCloseModel}) {
  const dispatch = useDispatch();

  const {opponentId, isLoading} = useSelector(
    (state) => state.deptCollection
  );
  const [checked, setChecked] = useState(true);

  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setFiles(files);
  };

  const defaultValues = {
    full_name: "",
    attribute: "",
    nationality: "",
    civil_number: "",
    phone_number: "",
    email: "",
    address: "",
  };

  const methods = useForm({defaultValues,});

  const {control, handleSubmit, formState: {errors},} = methods;

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (let key in data) {
      if (data[key] !== null && data[key] !== undefined) {
        formData.append(key, data[key]);
      }
    }

    formData.append("opponent", opponentId);
    formData.append("save_to_contact", checked ? 1 : 0);

    const filesArray = Array.from(files || []);
    filesArray.forEach((file, index) => {
      formData.append(`attachments[${index}]file`, file);
    });

    dispatch(createDependency({data: formData})).then((action) => {
      if (createDependency?.fulfilled?.match(action)) {
        onCloseModel();
        dispatch(getOpponentsDetails({id: opponentId}));
      }
    });
  };

  // ----------- JSX Code -------------
  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{...ModalSx}}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src="/assets/icons/x.svg" alt="" style={{}}/>
          </Box>

          <Typography sx={modalTitleSx}>إضافة كفيل </Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}

            <Grid container columnSpacing={4} mt={1}>
              <Grid item xs={12} sm={6} mt={1}>
                <Typography sx={lableStyle}>الاسم</Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="full_name"
                    control={control}
                    placeholder="ادخل اسم الكفيل"
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <Typography sx={lableStyle}>
                  الصفة
                </Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="attribute"
                    placeholder="ادخل صفة الكفيل"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} mt={1}>
                <Typography sx={lableStyle}>الجنسية</Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="nationality"
                    control={control}
                    placeholder="ادخل جنسية الكفيل"
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <Typography sx={lableStyle}>الرقم المدني</Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="civil_number"
                    control={control}
                    placeholder="ادخل الرقم المدني للكفيل"
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} mt={1}>
                <Typography sx={lableStyle}>رقم الهاتف </Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="phone_number"
                    placeholder="9xxxxxxx"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <Typography sx={lableStyle}>البريد الإلكتروني </Typography>
                <Box style={{display: "flex"}}>
                  <RHFTextField
                    name="email"
                    placeholder="ادخل البريد الإلكتروني"
                    control={control}
                    sx={{
                      ...txtFieldBorderStyle,
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography sx={lableSx}> العنوان</Typography>
                <RHFTextField
                  name="address"
                  control={control}
                  placeholder="ادخل عنوان الكفيل"
                  multiline
                  rows={3}
                  type={"text"}
                  sx={{
                    ...multiRowBorderStyle,
                    "& .MuiOutlinedInput-input": {
                      width: "100%",
                    },
                  }}
                />
              </Grid>

              {/* upload attachment */}
              <Grid item xs={12} sm={12} md={6} mt={1}>
                <Box>
                  <Typography sx={lableSx}>المرفقات</Typography>
                  <RHFTextField
                    name="attachments"
                    control={control}
                    type="file"
                    InputLabelProps={{shrink: true}}
                    sx={{
                      ...txtFieldBorderStyle,
                      width: "100%",
                      marginRight: "12px",
                    }}
                    onChange={handleFileChange}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={checkBoxConatainerStyle}>
                  <Checkbox
                    sx={{...checkBoxSx}}
                    checked={checked}
                    onClick={() => {
                      setChecked(!checked);
                    }}
                  />
                  <Typography sx={lableCheckBoxStyle}>
                    تسجيل في جهات الاتصال
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/** actions Button */}
            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                // type="submit"
                onClick={handleSubmit(onSubmit)}
                variant="Contained"
                sx={saveButtonStyle}
                loading={isLoading}
              >
                حفظ
              </LoadingButton>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="button"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
