export const containerStyle = {
  paddingLeft: {xs: "10px", sm: "0px"},
  paddingRight: {xs: "10px", sm: "0px"},
};

export const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};

export const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
};

export const createLawsuitButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const createReportButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2F2B2C",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const dividerStyle = {
  mt: "20px",
  mb: "20px",
};

export const txtFieldBorderStyle = {
  width: "100%",
  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

export const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#2F2B2C",
  lineHeight: "20px",
  paddingBottom: "10px",
  mt: "10px",
};

export const nameFileStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#FEFEFE",
  lineHeight: "20px",
};

export const fileBoxStyle = {
  bgcolor: "#6A8490",
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "fit-content",
  marginLeft: "10px",
  marginRight: "10px",
  marginBottom: "8px",
  marginTop: "8px",
};

export const boxStyle = {
  display: "flex",
  flexDirection: "column",
};

export const labelCheckBoxStyle = {
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "20px",
  color: "#2F2B2C",
  mb: "10px",
};

export const checkBoxConatainerStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  flexDirection: "column",
};