import React, {useState} from "react";
import {Box, Modal, Typography, Backdrop, Alert, Grid, Button,} from "@mui/material";
import FormProvider from "../../../../components/hook-form";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {useDispatch} from "react-redux";
import YearCustomDatePicker from "../../../../components/custom-datepicker/YearCustomDatePicker";
import {
  setMovmentDateAfter,
  setMovmentDateBefore,
  setEmplyeeId,
  setEmplyeeName,
} from "../../../../redux/slices/deptCollection";
import {toast} from "react-toastify";
import EmployeeSelect from "../../../../components/custom-select/EmployeeSelect";
// -- Styles --
import {
  ModalSx,
  loadingButtonStyle,
  modalTitleSx,
  buttonStyle,
  boxOfActionButtonStyle,
  lableSx,
  boxOfModalStyle,
} from "../../../../styles/CustodyExpenseModalStyles";
import {getCustudyExpenseService} from "../../../../redux/services/debt_collection_service";

//_______________________________________________________________

export default function CustodyExpenseModal({open, onCloseModel}) {
  const dispatch = useDispatch();

  const defaultValues = {
    employee: "",
    movement_date_before: null,
    movement_date_after: null,
  };

  const formSchema = Yup.object().shape({
    employee: Yup.string().required("يجب اختيار موظف"),
    movement_date_before: Yup.date().nullable(),
    movement_date_after: Yup.date().nullable(),
  });

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  });

  const {control, setValue, handleSubmit, formState: {errors}} = methods;

  const onSubmit = async (data) => {
    dispatch(
      getCustudyExpenseService({
        id: data.employee,
        movement_date_before: data.movement_date_before,
        movement_date_after: data.movement_date_after,
      })
    ).then((response) => {
      if (response?.payload?.status === 200) {
        onCloseModel();
      } else {
        response?.payload?.errors?.map((error, index) => toast.warn(error));
      }
    });
  };

  function handleChangeEmployee(value) {
    setValue("employee", value.id, {shouldValidate: true});
    dispatch(setEmplyeeId(value.id));
    dispatch(setEmplyeeName(value.first_name));
  }

  // ----------- JSX Code -------------

  return (
    <>
      <Modal
        open={open}
        onClose={onCloseModel}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        slots={{backdrop: Backdrop}}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={{...ModalSx}}>
          <Box sx={boxOfModalStyle} onClick={onCloseModel}>
            <img src={"/assets/icons/x.svg"} alt="close" style={{}}/>
          </Box>

          <Typography sx={modalTitleSx}>عهدة المصروفات </Typography>

          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {!!errors.afterSubmit && (
              <Alert severity="error">{errors.afterSubmit.message}</Alert>
            )}
            <Typography sx={lableSx}>
              اختر الموظف والفترة الزمنية التي تريد استعراض عهدة المصروفات لها
            </Typography>
            <Grid container columnSpacing={4} mt={1}>
              <Grid item xs={12}>
                <Typography sx={lableSx}> الموظف</Typography>
                <EmployeeSelect
                  name={"employee"}
                  handleChange={handleChangeEmployee}
                />
                {errors.employee &&
                <Alert severity="error">{errors.employee.message}</Alert>} {/* Display validation error */}
              </Grid>
            </Grid>
            <Grid container columnSpacing={4} mt="10px">
              <Grid item xs={12} sm={6}>
                <YearCustomDatePicker
                  name="movement_date_after"
                  control={control}
                  setValue={setValue}
                  onChange={(date) => {
                    dispatch(setMovmentDateAfter(date.format("YYYY-MM-DD")));
                    setValue("movement_date_after", date.format("YYYY-MM-DD"), {
                      shouldValidate: true,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <YearCustomDatePicker
                  name="movement_date_before"
                  control={control}
                  setValue={setValue}
                  onChange={(date) => {
                    dispatch(setMovmentDateBefore(date.format("YYYY-MM-DD")));
                    setValue(
                      "movement_date_before",
                      date.format("YYYY-MM-DD"),
                      {
                        shouldValidate: true,
                      }
                    );
                  }}
                />
              </Grid>
            </Grid>

            {/** actions Button */}
            <Box sx={boxOfActionButtonStyle}>
              <LoadingButton
                fullWidth
                disableRipple
                size="large"
                type="submit"
                variant="Contained"
                sx={loadingButtonStyle}
              >
                التالي
              </LoadingButton>
              <Button
                fullWidth
                disableRipple
                size="large"
                type="button"
                variant="Contained"
                sx={buttonStyle}
                onClick={onCloseModel}
              >
                إلغاء
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}
