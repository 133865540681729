import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";

import {Box, IconButton, Pagination, Tooltip} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";

import React, {useEffect, useState} from "react";

import {styled} from "@mui/material/styles";

import CustudtExpenseListTableRow from "./CustudtExpenseListTableRow";

import {
  TableHeadCustom,
  TableNoData,
  TableSelectedAction,
  useTable,
} from "../../../../components/table";

import Iconify from "../../../../components/iconify";

import Scrollbar from "../../../../components/scrollbar/Scrollbar";

import LoadingTableRows from "../../../../components/loadingSkeleton/LoadingTableRows";

import {setCustodyExpenseLoaded, setCustodyExpensePage} from "../../../../redux/slices/deptCollection";

// ----------------------------------------------------------------------

// --------- Style ------------

const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  height: "auto",
  overflow: "unset",
  borderColor: theme.palette.primary.main,
  border: "1.99262px 1.99262px 9.9631px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  background: "#fff",
}));

const tableHolderSx = {
  minWidth: 800,
};

const TABLE_HEAD = [
  {id: "المسلسل", label: "المسلسل", align: "center"},
  {id: "البيان", label: "البيان", align: "center"},
  {id: "تاريخ الحركة", label: "تاريخ الحركة", align: "center"},
  {id: "الموظف", label: "الموظف", align: "center"},
  {id: "المدين", label: "المدين", align: "center"},
  {id: "الدائن", label: "الدائن", align: "center"},
  {id: "الرصيد", label: " الرصيد", align: "center"},
];

// ----------------------------------------------------------------------

export default function CustudyExpenseList() {
  const dispatch = useDispatch();

  const {isLoading, custudyExpense, custodyExpenseCount, custodyExpensePage} = useSelector(
    (state) => state.deptCollection
  );

  const {
    dense,
    order,
    orderBy,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
  } = useTable();

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(custudyExpense);
  }, [dispatch, custudyExpense]);

  const handlePagination = (value) => {
    dispatch(setCustodyExpensePage(value));
    dispatch(setCustodyExpenseLoaded(false));
  };

  return (
    <>
      <StyledTableContainer>
        {/*        <TableSelectedAction
          dense={dense}
          numSelected={selected?.length}
          rowCount={tableData?.length}
          onSelectAllRows={(checked) =>
            onSelectAllRows(
              checked,
              tableData?.map((row) => row?.id)
            )
          }
          action={
            <Tooltip title="حذف">
              <IconButton color="#FFFFF">
                <Iconify icon="eva:trash-2-outline"/>
              </IconButton>
            </Tooltip>
          }
        />*/}
        <Scrollbar>
          <Table size={dense ? "small" : "medium"} sx={{...tableHolderSx}}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected.length}
              onSort={onSort}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row) => row?.id)
                )}
            />
            <TableBody>
              <>
                {!isLoading ? (
                  <>
                    {tableData?.map((row) => (
                      <CustudtExpenseListTableRow
                        key={row.id}
                        row={row}
                        onSelectRow={() => onSelectRow(row?.id)}
                        //

                        selected={selected?.includes(row?.id)}
                      />
                    ))}
                  </>
                ) : (
                  <LoadingTableRows number={3} cellsNum={3}/>
                )}
              </>
              {tableData?.length === 0 && <TableNoData isNotFound/>}
            </TableBody>
          </Table>
        </Scrollbar>
        {tableData?.length > 0 &&
        <Box display={"flex"} justifyContent={"end"} py={2}>
          <Pagination
            color="primary"
            page={custodyExpensePage}
            count={custodyExpenseCount}
            onChange={(event, value) => handlePagination(value)}
          />
        </Box>
        }
      </StyledTableContainer>
    </>
  );
}
