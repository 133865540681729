import {createSlice} from "@reduxjs/toolkit";

import {getClientNeighbors} from "../services/clientService";

// ----------------------------------------------------------------------

const initialState = {
  previousClient: null,
  nextClient: null,
  error: null,
};

const slice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: {
    [getClientNeighbors.pending]: (state) => {
      state.error = null;
    },
    [getClientNeighbors.fulfilled]: (state, action) => {
      state.previousClient = action.payload?.data?.data?.prev_client;
      state.nextClient = action.payload?.data?.data?.next_client;
    },
    [getClientNeighbors.rejected]: (state, action) => {
      state.error = action.payload?.error || action.payload?.message;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;
