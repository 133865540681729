export const checkBoxSx = {
  color: "#6A8490",
  "&.Mui-checked": {
    color: "#6A8490",
  },
};

export const caseNameStyle = {
  fontSize: "14px",
  fontWeight: 400,
  color: "#6A8490",
  lineHeight: "20px",
};

export const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
};

export const bigFlexBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  mt: {xs: "15px", sm: "0px"},
};

export const topSaveButtonStyle = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "28px",
  color: "#FEFEFE",
  bgcolor: "#6A8490",
  borderRadius: "5px",
  p: "10px 18px 10px 18px",
  width: "160px",
  height: "40px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const topCancelButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#2F2B2C",
  bgcolor: "#FFFFFF",
  borderRadius: "5px",
  width: "160px",
  height: "40px",
  p: "12px 20px 12px 20px",
  border: "1px solid #CCDDE4",
  marginLeft: "20px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const dividerStyle = {
  mt: "20px",
};

export const titleSx = {
  fontSize: "14px",
  fontWeight: 400,
  color: "rgba(119, 117, 118, 1)",
  lineHeight: "20px",
  pr: "10px",
};

export const ModalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "5px",
  width: {xs: "95%", sm: "543px", md: "766px", lg: "766px"},
  bgcolor: "#CCDDE4",
  pt: {xs: "60px", md: "24px", lg: "30px"},
  pb: {xs: "40px", md: "24px", lg: "30px"},
  px: {xs: "10px", sm: "20px", md: "20px", lg: "30px"},
  overflowY: "auto",
  scrollbarWidth: "none",
  height: "85%",
};

export const txtFieldBorderStyle = {
  width: "100%",

  boxShadow: "#101828",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
    boxShadow: "#101828",
  },
  "& .MuiOutlinedInput-root": {
    background: "#fff",
    borderRadius: "5px",
    boxShadow: "#101828",
    height: "44px",
    border: "1px solid #D0D5DD",
    "&:hover fieldset": {
      border: "1px solid #D0D5DD",
      borderColor: "#D0D5DD",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D0D5DD",
    },
  },
};

export const loadingButtonStyle = {
  fontSize: "16px",
  width: "173px",
  height: "48px",
  fontWeight: 600,
  bgcolor: "#97BDCE",
  color: "#2F2B2C",
  p: "10px 18px 10px 18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

export const modalTitleSx = {
  textAlign: "center",
  fontWeight: 500,
  color: "#2F2B2C",
  fontSize: "24px",
  lineHeight: "36px",
  pb: "10px",
};

export const buttonStyle = {
  ml: "10px",
  fontSize: "16px",
  height: "48px",
  width: "173px",
  fontWeight: 400,
  bgcolor: "#FFF",
  color: "#344054",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "18px",
  paddingRight: "18px",
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
  borderRadius: "5px",
};

export const boxOfActionButtonStyle = {
  display: "flex",
  justifyContent: "center",
  mt: "30px",
};

export const lableSx = {
  fontSize: "14px",
  fontWeight: 500,
  color: "rgba(47, 43, 44, 1)",
  paddingBottom: "7px",
};

export const boxOfModalStyle = {position: "absolute", top: "22px", left: "22px"};

export const saveAddButtonStyle = {
  width: {xs: "240px", sm: "400px"},
  mt: "30px",
  fontSize: "16px",
  height: "48px",
  fontWeight: 400,
  bgcolor: "rgba(255, 255, 255, 1)",
  color: "rgba(213, 26, 82, 1)",
  boxShadow: "rgba(16, 24, 40, 0.05)",
  paddingLeft: "28px",
  paddingRight: "28px",
  paddingTop: "16px",
  paddingBottom: "16px",
  borderRadius: "5px",

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const ITEM_HEIGHT = 48;

export const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

export const nameFileStyle = {
  fontSize: "14px",
  fontWeight: 500,
  color: "#FEFEFE",
  lineHeight: "20px",
};

export const fileBoxStyle = {
  bgcolor: "#6A8490",
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "fit-content",
  marginLeft: "10px",
  marginRight: "10px",
  marginBottom: "8px",
  marginTop: "8px",
};